import { createApi } from '@reduxjs/toolkit/query/react'

import {type AiConfigTestCreate} from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiConfigTestSlice = createApi({
  reducerPath: 'aiConfigTestApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiConfigTest', 'aiConfigTestList'],
  endpoints: (builder) => ({
    getAiConfigTestList: builder.query({
      query: ({
        state,
        aiConfigSid,
        sortOrder,
        pageToken,
      }: {
        state?: string
        aiConfigSid?: string
        sortOrder?: string
        pageToken?: string
      }) => ({
        url: `/v1/AiConfigTests`,
        params: {
          state,
          aiConfigSid,
          sortOrder,
          pageToken,
        },
      }),
      providesTags: ['aiConfigTestList'],
    }),
    getAiConfigTest: builder.query({
      query: (sid: string) => `/v1/AiConfigTests/${sid}`,
      providesTags: ['aiConfigTest'],
    }),
    createAiConfigTest: builder.mutation({
      query: (body: AiConfigTestCreate) => ({
        url: `/v1/AiConfigTests/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiConfigTestList'],
    }),
  }),
})

export const {
  useLazyGetAiConfigTestListQuery,
  useGetAiConfigTestQuery,
  useCreateAiConfigTestMutation,
} = apiAiConfigTestSlice
