import React, { type FC, type JSX } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useAuthWithCookie } from '../hooks'

const ProtectedRoutes: FC = (): JSX.Element => {
  useAuthWithCookie()
  const token: string | null = localStorage.getItem('token')
  return <>{token != null ? <Outlet /> : <Navigate to="/login" />}</>
}

export default ProtectedRoutes
