import React, {type ChangeEventHandler, type MouseEvent, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {Headline} from '../../../components'
import { TopSection } from '../../../components/TopSection/TopSection'
import {Card} from "../../../components/Card/Card";
import {getSidPath} from "../../../utils/sids/sids";
import clsx from "clsx";

const SidSearch = (): JSX.Element => {

  const navigate = useNavigate()
  const { sid } = useParams()
  const [disable, setDisable] = useState<boolean>(true)
  const [warning, setWarning] = useState<boolean>(false)
  const [sidPath, setSidPath] = useState<string>('')
  const [text, setText] = useState(sid !== undefined ? sid : '')

  useEffect(() => {
    if(sid !== undefined && text === sid) {
      validateSid(sid)
    }
  })

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e): void => {
    const input = e.target.value;
    if(/^[A-Za-z0-9]*$/.test(input)) {
      setText(input)
      validateSid(input)
    }
  }

  const validateSid = (inputSid:string | null): void => {
    if (inputSid === null || inputSid.length !== 30 || !/^[A-Za-z0-9]*$/.test(inputSid)) {
      setDisable(true);
      setWarning(false);
    } else {
      const path = getSidPath(inputSid);
      if (path === 'Unknown') {
        setWarning(true);
        setDisable(true);
      } else {
        setDisable(false);
        setWarning(false)
        setSidPath(path);
      }
    }
  }

  const handleOpen = (event: MouseEvent): void => {
    if (event.metaKey) {
      window.open(
          sidPath,
          '_blank',
          'noreferer'
      )
    } else {
      navigate(sidPath)
    }
  }

  const handleKeyDown = (e:any): void => {
    if(e?.key === 'Enter') {
      if(!disable) {
        navigate(sidPath)
      } else {
        setWarning(true);
      }
    }
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Sids
          </Headline>
        </TopSection>
      </div>
      <div className="p-5">
        <Card>
          <div className="inline-flex">
            <div className="w-[400px] pr-5">
              <textarea
                  rows={1}
                  className="text-sm rounded-md border border-transparent transition shadow-md w-full resize-none"
                  onChange={handleChange}
                  placeholder='Type your SID here...'
                  value={text}
                  onKeyDown={handleKeyDown}
              ></textarea>
            </div>
            <div className="flex justify-end mt-auto">
              <button
                  onClick={handleOpen} disabled={disable}
                  className={clsx(
                      'flex justify-center font-semibold font-sans px-5 py-2 rounded-3xl text-sm shadow-sm antialised',
                      { 'bg-offBlack-lightest text-offBlack-light': disable },
                      { 'bg-blue-500 text-white': !disable }
                  )}
                  >
                Search
              </button>
            </div>
          </div>
          <div hidden={!warning} className="text-xs italic pt-3 pb-1">
            <span>This SID is not possible to identify. Please, verify...</span>
          </div>
        </Card>
      </div>
    </>
  )
}

export default SidSearch
