import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface PaginationProps {
  disablePrev: boolean
  disableNext: boolean
  goNextPage: () => void
  goPrevPage: () => void
}

export const Pagination = ({
  disablePrev,
  disableNext,
  goNextPage,
  goPrevPage,
}: PaginationProps): JSX.Element => {
  return (
    <div className="mb-10">
      <div className="flex justify-end items-center px-5 sm:px-8">
        <div className="flex items-center gap-[5px]">
          <button
            disabled={disablePrev}
            className={clsx(
              'rounded-full h-7 w-7 p-[5px] flex items-center justify-center',
              {
                hidden: disablePrev,
                'bg-white shadow-base': !disablePrev,
              }
            )}
            onClick={() => {
              goPrevPage()
            }}
          >
            <FontAwesomeIcon icon={light('arrow-left')} />
          </button>
          <button
            disabled={disableNext}
            className={clsx(
              'rounded-full h-7 w-7 p-[5px] flex items-center justify-center',
              {
                hidden: disableNext,
                'bg-white shadow-base': !disableNext,
              }
            )}
            onClick={() => {
              goNextPage()
            }}
          >
            <FontAwesomeIcon icon={light('arrow-right')} />
          </button>
        </div>
      </div>
    </div>
  )
}
