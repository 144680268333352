import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect, useMemo, useState} from 'react'
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import { TopSection } from '../../../components/TopSection/TopSection'
import {
  useLazyGetAiParseReviewListQuery
} from '../../../services/aiparsereviews/AiParseReviewService'
import { type AiParseReview } from '../../../types'
import {FilterType, TableFilters} from "../../../components/TableFilters/TableFilters";

const AiParseReviewList = (): JSX.Element => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    reviewed?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)

  const [getAiParseReviewList, { data: aiParseReviewList, isLoading }] = useLazyGetAiParseReviewListQuery()
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const reviewed = searchParams.get('reviewed') ?? undefined
    const organizationSid = searchParams.get('organizationSid') ?? undefined
    const referenceSid = searchParams.get('referenceSid') ?? undefined
    const state = searchParams.get('state') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? "Desc"
    const pageToken = searchParams.get('pageToken') ?? undefined
    const pageSize = searchParams.get('pageSize') ?? undefined

    const currentSearchParams = Object.fromEntries(
        Object.entries({
          reviewed,
          organizationSid,
          referenceSid,
          state,
          sortOrder,
          pageToken,
          pageSize
        }).map(([key, value], _) => [key, value?.trim()])
    )

    const currentParamsFiltered = Object.fromEntries(
        Object.entries(currentSearchParams)
          .filter(([_, value]) => value !== undefined)
    )

    setSearchParams(currentParamsFiltered)
    getAiParseReviewList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'Reviewed',
        attribute: 'reviewed',
        value: params.get('reviewed') ?? undefined,
        type: FilterType.SELECT,
        options: ['true', 'false'],
      },
      {
        label: 'Organization SID',
        attribute: 'organizationSid',
        value: params.get('organizationSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'Reference SID',
        attribute: 'referenceSid',
        value: params.get('referenceSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'State',
        attribute: 'state',
        value: params.get('state') ?? undefined,
        type: FilterType.SELECT,
        options: ['created', 'parsing', 'processing', 'applied', 'failed_processing', 'invalid'],
      },
    ]
  }, [params])

  if (isLoading || aiParseReviewList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? "Desc"

    if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const statesDescription = [
    'created: a new request to process data using AI has been created',
    'parsing: the AI engine is parsing the data',
    'processing: applying the parsed data changes to Factor',
    'applied: all changes to Factor has been applied',
    'failed_processing: an error occurred while applying the changes to Factor',
    'invalid: the AI engine could not parse the data',
  ]

  const aiParseReviewHeaders = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'CREATED',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? "Desc") as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'LAST UPDATE', attribute: 'updated' },
    { label: 'AI PARSE SID', attribute: 'aiParseSid', link: true },
    { label: 'REFERENCE SID', attribute: 'referenceSid', link: true },
    { label: 'STATE', attribute: 'state', tooltips: statesDescription },
    { label: 'REVIEWED', attribute: 'reviewed' },
  ]

  const aiParseReviewData = aiParseReviewList?.aiParseReviews?.map(
    (aiParseReview: AiParseReview) => ({
      sid: {
        value: (<a href={`/aiparsereviews/${aiParseReview.sid}`}>{aiParseReview.sid}</a>)
      },
      created: { value: aiParseReview.created },
      updated: { value: aiParseReview.updated },
      aiParseSid: {
        value: (<a href={`/aiparses/${aiParseReview.aiParseSid}`}>{aiParseReview.aiParseSid}</a>)
      },
      referenceSid: {
        value: (<a href={`/inboundemails/${aiParseReview.referenceSid}`}>{aiParseReview.referenceSid}</a>)
      },
      state: { value: aiParseReview.state },
      reviewed: {
        value: aiParseReview.reviewed !== null ? (<FontAwesomeIcon icon={light('check')} />) : (<FontAwesomeIcon icon={light('xmark')} />),
      },
    })
  )

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseReviewList?.page?.nextPageToken)
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseReviewData?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseReviewList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseReviewData?.page?.previousPageToken)
  }

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const disablePrev =
    aiParseReviewList?.page === undefined ||
    aiParseReviewList?.page?.previousPageToken === null
  const disableNext =
    aiParseReviewList?.page === undefined ||
    aiParseReviewList?.page?.nextPageToken === null

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            AI Parse Reviews
          </Headline>
        </TopSection>
      </div>
      <div>
        <TableFilters
            filters={tableFilters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={aiParseReviewData} headers={aiParseReviewHeaders} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default AiParseReviewList
