import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type CSSProperties, type FC, useRef } from 'react'

import { type OrderMemberAvatars } from 'components/AvatarList/AvatarList'
import { Tooltip } from 'components/Tooltip/Tooltip'

export interface AvatarProps {
  content?: JSX.Element | string
  color?: string
  extraClasses?: string
  overlap?: boolean
  border?: string
  tooltipOn?: boolean
  tooltipData?: OrderMemberAvatars
}

export const Avatar: FC<AvatarProps> = ({
  content = <FontAwesomeIcon icon={light('plus')} />,
  color = '#0d0d0d',
  extraClasses = '',
  overlap = false,
  border = 'none',
  tooltipOn = false,
  tooltipData,
}: AvatarProps): JSX.Element => {
  const badgeContent: {
    content?: JSX.Element | string
    styles: CSSProperties
  } = {
    content,
    styles: {
      fontFamily: 'Roboto Mono',
      backgroundColor: color,
      marginLeft: overlap ? '-7px' : '',
      border: `${border !== 'none' ? `1px solid ${border}` : 'none'}`,
    },
  }

  const tooltipRef = useRef(null)

  return (
    <div>
      <div
        style={badgeContent.styles}
        className={`rounded-full w-11 text-sm text-offWhite-light content-center ${extraClasses}`}
        ref={tooltipRef}
      >
        <span>{badgeContent.content}</span>
      </div>
      {tooltipOn && tooltipData && (
        <Tooltip
          position={'top'}
          ref={tooltipRef}
          bgColor="bg-offBlack-tooltip"
        >
          <div className="flex bg-offBlack-tooltip">
            <div className="w-12">
              <div className="mt-1.5 gap-4 h-18 flex flex-col justify-end items-start font-mono text-xs text-offBlack-light">
                <span>EMAIL</span>
                <span>ORG</span>
                <span>TYPE</span>
              </div>
            </div>
            <div className="w-40">
              <div className="gap-2 h-18 flex flex-col justify-end items-start">
                <span className="font-sans text-offWhite-light text-base">
                  {tooltipData.email}
                </span>
                <span className="font-sans text-offWhite-light text-base">
                  {tooltipData.orgName}
                </span>
                <span className="font-sans text-offWhite-light text-base">
                  {tooltipData.memberType}
                </span>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  )
}
