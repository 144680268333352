import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useLazyGetAiParseInputListQuery } from '../../../services/aiparseinputs/AiParseInputService'
import { type AiParseInput } from '../../../types'

const aiParseInputList = (): JSX.Element => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    organizationSid?: string
    aiParseSid?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getaiParseInputList, { data: aiParseInputList, isLoading }] =
    useLazyGetAiParseInputListQuery()

  const TEXT_DATA_MAX_SIZE = 30;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const organizationSid = searchParams.get('organizationSid') ?? undefined
    const aiParseSid = searchParams.get('aiParseSid') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      organizationSid,
      aiParseSid,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getaiParseInputList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'Organization SID',
        attribute: 'organizationSid',
        value: params.get('organizationSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'AI Parse SID',
        attribute: 'aiParseSid',
        value: params.get('aiParseSid') ?? undefined,
        type: FilterType.TEXT,
      },
    ]
  }, [params])

  if (isLoading || aiParseInputList === undefined) {
    return <div>Loading...</div>
  }

  const aiParseHeaders = [
    { label: 'SID', attribute: 'sid', link: true },
    { label: 'CREATED', attribute: 'created' },
    { label: 'ORGANIZATION SID', attribute: 'organizationSid' },
    { label: 'AI PARSE SID', attribute: 'aiParseSid', link: true },
    { label: 'TEXT DATA', attribute: 'textData' },
    { label: 'INPUT SOURCE', attribute: 'inputSource' },
  ]

  const aiParseInputData = aiParseInputList?.aiParseInputList?.map((aiParseInput: AiParseInput) => ({
    sid: {
      value: aiParseInput.sid,
      handleClick: (event: KeyboardEvent) => {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/aiparseinputs/${aiParseInput.sid}`, '_blank', 'noreferer')
        } else {
          navigate(`/aiparseinputs/${aiParseInput.sid}`)
        }
      },
    },
    created: { value: aiParseInput.created },
    organizationSid: { value: aiParseInput.organizationSid },
    aiParseSid: {
      value: aiParseInput.aiParseSid,
      handleClick: (event: KeyboardEvent) => {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/aiparses/${aiParseInput.aiParseSid}`, '_blank', 'noreferer')
        } else {
          navigate(`/aiparses/${aiParseInput.aiParseSid}`)
        }
      },
    },
    textData: { value: aiParseInput.textData?.substring(0, TEXT_DATA_MAX_SIZE) },
    inputSource: { value: aiParseInput.inputSource },
    }
  ));

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseInputList?.page?.nextPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseInputList?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseInputList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseInputList?.page?.previousPageToken)
  }

  const disablePrev =
    aiParseInputList?.page === undefined ||
    aiParseInputList?.page?.previousPageToken === null
  const disableNext =
    aiParseInputList?.page === undefined || aiParseInputList?.page?.nextPageToken === null

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            AI Parse Inputs
          </Headline>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={aiParseInputData} headers={aiParseHeaders} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default aiParseInputList
