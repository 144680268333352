import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FC, Fragment, type ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { Sidebar } from 'components/Sidebar/Sidebar'

import { notificationSelector, slideOverSelector } from '../../store/selectors'
import { Notification } from '../Notification/Notification'

import { CreateAiPrompt } from 'slideovers/CreateAiPrompt'
import { CreateAiConfigTest } from "slideovers/CreateAiConfigTest";
import { CreateAiConfig } from 'slideovers/CreateAiConfig'
import { CreateAiConfigItem } from 'slideovers/CreateAiConfigItem'

interface LayoutProps {
  children?: ReactNode
}

const slideOvers: any = {
  CreateAiPrompt: <CreateAiPrompt />,
  CreateAiConfigTest: <CreateAiConfigTest />,
  CreateAiConfig: <CreateAiConfig />,
  CreateAiConfigItem: <CreateAiConfigItem />,
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const notification = useSelector(notificationSelector)
  const { name } = useSelector(slideOverSelector)
  console.log(name)
  return (
    <Fragment>
      <Notification {...notification} />
      <div className="flex min-h-screen">
        <Sidebar
          navItems={[
            {
              label: 'Organizations',
              url: '/organizations',
              icon: <FontAwesomeIcon icon={light('people-roof')} />,
            },
            {
              label: 'Inbound Emails',
              url: '/inboundemails',
              icon: <FontAwesomeIcon icon={light('envelope')} />,
            },
            {
              label: 'AI Parses',
              url: '/aiparses',
              icon: <FontAwesomeIcon icon={light('brain')} />,
            },
            {
              label: 'AI Parse Reviews',
              url: '/aiparsereviews',
              icon: <FontAwesomeIcon icon={light('user-bounty-hunter')} />,
            },
            {
              label: 'AI Parse Inputs',
              url: '/aiparseinputs',
              icon: <FontAwesomeIcon icon={light('book')} />,
            },
            {
              label: 'AI Prompts',
              url: '/aiprompt',
              icon: <FontAwesomeIcon icon={light('square-terminal')} />,
            },
            {
              label: 'Event Relations',
              url: '/eventrelations',
              icon: <FontAwesomeIcon icon={light('calendar-users')} />,
            },
            {
              label: 'AI Configs',
              url: '/aiconfigs',
              icon: <FontAwesomeIcon icon={light('sliders')} />,
            },
            {
              label: 'AI Config Items',
              url: '/aiconfigitems',
              icon: <FontAwesomeIcon icon={light('list')} />,
            },
            {
              label: 'AI Config Tests',
              url: '/aiconfigtest',
              icon: <FontAwesomeIcon icon={light('flask-vial')} />,
            },
            {
             label: 'Sids',
             url: '/sids',
             icon: <FontAwesomeIcon icon={light('magnifying-glass')} />,
            },
          ]}
        />
        <div className="grow bg-offWhite-light">{children}</div>
      </div>
      {name !== undefined && name !== null ? <>{slideOvers[name]}</> : null}
    </Fragment>
  )
}
