import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { openSlideOver } from 'store/slideOvers'

import { Button, Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import {type AiConfig} from '../../../types'
import {useLazyGetAiConfigListQuery} from "../../../services/aiconfigs/AiConfigService";

const AiConfigList = (): JSX.Element => {
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    state?: string
    sortOrder?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getAiConfigList, { data: aiConfigList, isLoading }] =
    useLazyGetAiConfigListQuery()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const state = searchParams.get('state') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      state,
      sortOrder,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getAiConfigList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'State',
        attribute: 'state',
        value: params.get('state') ?? undefined,
        type: FilterType.SELECT,
        options: ['unlocked', 'locked', 'disabled'],
      },
    ]
  }, [params])

  if (isLoading || aiConfigList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? undefined

    if (sortOrder === undefined) {
      currentSearchParams.set('sortOrder', 'Asc')
    } else if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const headers = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'CREATED',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? undefined) as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'LAST UPDATED', attribute: 'updated' },
    { label: 'TITLE', attribute: 'title', overFlow: true},
    { label: 'STATE', attribute: 'state'},
  ]

  const data = aiConfigList?.aiConfigList?.map((aiConfig: AiConfig) => ({
    sid: {
      value: (<a href={`/aiconfigs/${aiConfig.sid}`}>{aiConfig.sid}</a>)
    },
    created: { value: aiConfig.created },
    updated: { value: aiConfig.updated},
    title: { value: aiConfig.title },
    state: { value: aiConfig.state },
  }))

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiConfigList?.page?.nextPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiConfigList?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiConfigList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiConfigList?.page?.previousPageToken)
  }

  const disablePrev =
      aiConfigList?.page === undefined ||
      aiConfigList?.page?.previousPageToken === null
  const disableNext =
      aiConfigList?.page === undefined ||
      aiConfigList?.page?.nextPageToken === null

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Configs</Headline>
            <div>
              <Button
                style="gradient"
                className="ml-4"
                onClick={() => {
                  dispatch(
                    openSlideOver({
                      name: 'CreateAiConfig',
                      data: ''
                    })
                  )
                }}
              >
                Create AI Config
              </Button>
            </div>
          </div>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={data} headers={headers} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default AiConfigList
