import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import AiParseInput from 'views/AiParseInputs/AiParseInput/AiParseInput'
import AiPromptList from 'views/AiPrompts/AiPromptList/AiPromptList'

import { Layout } from '../components'
import AiParseInputList from '../views/AiParseInputs/AiParseInputList/AiParseInputList'
import AiParseReview from '../views/AiParseReviews/AiParseReview/AiParseReview'
import AiParseReviewList from '../views/AiParseReviews/AiParseReviewList/AiParseReviewList'
import AiParse from '../views/AiParses/AiParse/AiParse'
import AiParseList from '../views/AiParses/AiParseList/AiParseList'
import AiPrompt from '../views/AiPrompts/AiPrompt/AiPrompt'
import EventRelation from '../views/EventRelations/EventRelation/EventRelation'
import EventRelationList from '../views/EventRelations/EventRelationList/EventRelationList'
import InboundEmail from '../views/InboundEmails/InboundEmail/InboundEmail'
import InboundEmailList from '../views/InboundEmails/InboundEmailList/InboundEmailList'
import Login from '../views/Login/Login'
import Organizations from '../views/Organizations/Organizations'
import ProtectedRoutes from './ProtectedRoutes'
import * as paths from './paths'
import AiConfigTest from "../views/AiConfigTests/AiConfigTest/AiConfigTest";
import AiConfigTestList from "../views/AiConfigTests/AiConfigTestList/AiConfigTestList";
import AiConfig from "../views/AiConfigs/AiConfig/AiConfig";
import AiConfigList from "../views/AiConfigs/AiConfigList/AiConfigList";
import SidSearch from "../views/Sids/SidSearch/SidSearch";
import AiConfigItem from 'views/AiConfigItems/AiConfigItem/AiConfigItem'
import AiConfigItemList from 'views/AiConfigItems/AiConfigItemList/AiConfigItemList'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={paths.ROOT} element={<Navigate to="/organizations" />} />
      <Route path={paths.LOGIN} element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path={paths.ORGANIZATIONS} element={<Organizations />} />
          <Route path={paths.INBOUND_EMAILS} element={<InboundEmailList />} />
          <Route
            path={paths.INBOUND_EMAILS_DETAILS}
            element={<InboundEmail />}
          />
          <Route path={paths.AI_PARSES} element={<AiParseList />} />
          <Route path={paths.AI_PARSES_DETAILS} element={<AiParse />} />
          <Route
            path={paths.AI_PARSE_REVIEWS}
            element={<AiParseReviewList />}
          />
          <Route
            path={paths.AI_PARSE_REVIEW_DETAILS}
            element={<AiParseReview />}
          />
          <Route
            path={paths.AI_PARSE_INPUT_DETAILS}
            element={<AiParseInput />}
          />
          <Route path={paths.AI_PARSE_INPUTS} element={<AiParseInputList />} />
          <Route path={paths.EVENT_RELATIONS} element={<EventRelationList />} />
          <Route
            path={paths.EVENT_RELATIONS_DETAILS}
            element={<EventRelation />}
          />
          <Route path={paths.AI_PROMPTS} element={<AiPromptList />} />
          <Route path={paths.AI_PROMPTS_DETAILS} element={<AiPrompt />} />
          <Route path={paths.AI_CONFIG_TEST} element={<AiConfigTestList />} />
          <Route path={paths.AI_CONFIG_TEST_DETAILS} element={<AiConfigTest />} />
          <Route path={paths.AI_CONFIG} element={<AiConfigList />} />
          <Route path={paths.AI_CONFIG_DETAILS} element={<AiConfig />} />
          <Route path={paths.SIDS} element={<SidSearch />} />
          <Route path={paths.SID_SEARCH} element={<SidSearch />} />
          <Route path={paths.AI_CONFIG_ITEMS} element={<AiConfigItemList />} />
          <Route path={paths.AI_CONFIG_ITEM_DETAILS} element={<AiConfigItem />} />
        </Route>
      </Route>
    </Route>
  )
)

export default router
