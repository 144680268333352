import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useLazyGetEventRelationListQuery } from '../../../services/eventrelations/EventRelationService'
import { type EventRelation } from '../../../types'
import { getReferenceFromRelation } from '../../../utils/sids/sids'

const EventRelationList = (): JSX.Element => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    organizationSid?: string
    eventType?: string
    relation?: string
    sortOrder?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getEventRelationList, { data: eventRelationList, isLoading }] =
    useLazyGetEventRelationListQuery()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const organizationSid = searchParams.get('organizationSid') ?? undefined
    const eventType = searchParams.get('eventType') ?? undefined
    const relation = searchParams.get('relation') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      organizationSid,
      eventType,
      relation,
      sortOrder,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getEventRelationList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'Event SID',
        attribute: 'eventSid',
        value: params.get('eventSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'Event Type',
        attribute: 'eventType',
        value: params.get('eventType') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'Relation',
        attribute: 'relation',
        value: params.get('relation') ?? undefined,
        type: FilterType.TEXT,
      },
    ]
  }, [params])

  if (isLoading || eventRelationList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? undefined

    if (sortOrder === undefined) {
      currentSearchParams.set('sortOrder', 'Asc')
    } else if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const headers = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'CREATED',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? undefined) as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'EVENT TYPE', attribute: 'eventType' },
    { label: 'RELATION', attribute: 'relation', overFlow: true, link: true },
    { label: 'EVENT SID', attribute: 'eventSid' },
    { label: 'ORGANIZATION SID', attribute: 'organizationSid' },
  ]

  const data = eventRelationList?.eventRelationList?.map(
    (eventRelation: EventRelation) => ({
      sid: {
        value: eventRelation.sid,
        handleClick: (event: KeyboardEvent) => {
          if (event.ctrlKey || event.metaKey) {
            window.open(
              `/eventrelations/${eventRelation.sid}?eventSid=${eventRelation.eventSid}`,
              '_blank',
              'noreferer'
            )
          } else {
            navigate(
              `/eventrelations/${eventRelation.sid}?eventSid=${eventRelation.eventSid}`
            )
          }
        },
      },
      created: { value: eventRelation.created },
      eventType: { value: eventRelation.eventType },
      relation: {
        value: eventRelation.relation,
        handleClick: (event: KeyboardEvent) => {
          const url = getReferenceFromRelation(eventRelation?.relation)
          if (url === 'Unknown') return
          if (event.ctrlKey || event.metaKey) {
            window.open(url, '_blank', 'noreferer')
          } else {
            navigate(url)
          }
        },
      },
      eventSid: { value: eventRelation.eventSid },
      organizationSid: { value: eventRelation.organizationSid },
    })
  )

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', eventRelationList?.page?.nextPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(eventRelationList?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', eventRelationList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(eventRelationList?.page?.previousPageToken)
  }

  const disablePrev =
    eventRelationList?.page === undefined ||
    eventRelationList?.page?.previousPageToken === null
  const disableNext =
    eventRelationList?.page === undefined ||
    eventRelationList?.page?.nextPageToken === null

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Event Relations
          </Headline>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={data} headers={headers} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default EventRelationList
