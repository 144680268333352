import { createApi } from '@reduxjs/toolkit/query/react'

import baseQuery from '../baseQuery'

export const apiAuthSlice = createApi({
  reducerPath: 'authApi',
  baseQuery,
  tagTypes: ['Session'],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (payload: { password: string; email: string }) => ({
        url: '/api/login',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        responseHandler: async (response): Promise<{ status: number }> => {
          return { status: response.status }
        },
      }),
    }),
    getSession: builder.mutation({
      query: (payload: { password: string; email: string }) => ({
        url: '/api/login',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        responseHandler: async (response): Promise<{ status: number }> => {
          return { status: response.status }
        },
      }),
    }),
    deleteLogout: builder.mutation({
      query: () => ({
        url: '/api/logout',
        method: 'DELETE',
        responseHandler: async (response): Promise<{ status: number }> => {
          return { status: response.status }
        },
      }),
    }),
    getAccessToken: builder.mutation({
      query: () => ({
        url: '/api/accesstoken',
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    getMagicLink: builder.mutation({
      query: (payload: { redirectTo: string; email: string }) => ({
        url: '/api/magiclink',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        responseHandler: async (response) => {
          return { status: response.status }
        },
      }),
    }),
    getSendMagicLink: builder.mutation({
      query: (email: string) => `/api/magiclink/email/${email}`,
    }),
  }),
})

export const {
  useDeleteLogoutMutation,
  useGetSessionMutation,
  useGetAccessTokenMutation,
  useGetMagicLinkMutation,
  useGetSendMagicLinkMutation,
} = apiAuthSlice
