export const getSidPath = (sid: string | null): string => {
  if (sid === null) return 'Unknown'

  if (sid.startsWith('IE')) {
    return `/inboundemails/${sid}`
  } else if (sid.startsWith('AR')) {
    return `/aiparsereviews/${sid}`
  } else if (sid.startsWith('AP')) {
    return `/aiparses/${sid}`
  } else if (sid.startsWith('PI')) {
    return `/aiparseinputs/${sid}`
  } else if (sid.startsWith('VR')) {
    return `/eventrelations/${sid}`
  } else if (sid.startsWith('PR')) {
    return `/aiprompt/${sid}`
  } else if (sid.startsWith('AC')) {
    return `/aiconfigs/${sid}`
  } else if (sid.startsWith('TC')) {
    return `/aiconfigtest/${sid}`
  } else if (sid.startsWith('CI')) {
    return `/aiconfigitems/${sid}`
  } else {
    return 'Unknown'
  }
}

export const getReferenceFromRelation = (relation: string | null): string => {
  console.log(relation)
  if (relation === null || relation.length < 30) return 'Unknown'
  const sid = relation.slice(relation.lastIndexOf(':') + 1)
  console.log(sid)
  console.log(sid.length)
  if (sid.length !== 30) return 'Unknown'
  console.log('length ok')
  return getSidPath(sid)
}

export const isSid = (value: string | null): boolean =>
  value !== null && value.length === 30 && getSidPath(value) !== 'Unknown'
