import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from 'assets/phoenix.png'
import { useFormik } from 'formik'
import React, { type FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Button, Headline } from '../../components'
import { FormField } from '../../components/FormField/FormField'
import { Notification } from '../../components/Notification/Notification'
import {
  useGetAccessTokenMutation,
  useGetMagicLinkMutation, // useGetSendMagicLinkMutation,
  useGetSessionMutation,
} from '../../services/auth/Auth'
import { type RootState, useAppDispatch } from '../../store'
import { openNotification } from '../../store/notifications'
import { notificationSelector } from '../../store/selectors'
import { setAccessToken } from '../../store/session'
import { HttpResponseStatus } from '../../utils/httpResponseStatus'

const Login: FC = () => {
  const [getSession] = useGetSessionMutation()
  const [getAccessToken] = useGetAccessTokenMutation()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [step, setStep] = useState<string>('email')
  const [validEmail, setValidEmail] = useState<boolean>(true)
  const notification = useSelector(notificationSelector)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const [getMagicLink] = useGetMagicLinkMutation()
  // const [getSendMagicLink, { isLoading }] = useGetSendMagicLinkMutation()

  const magicLinkexpired = searchParams.get('expired')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    initialErrors: {
      email: '',
    },
    initialTouched: {
      email: true,
      password: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        const response = (await getSession({ email, password })) as {
          data?: { status: number }
          error: { status: number }
        }
        console.log('response', response)
        if (response?.data?.status === HttpResponseStatus.OK) {
          const {
            data: { accessToken },
          } = (await getAccessToken({})) as {
            data: { accessToken: string; email: string; phoenixUserSid: string }
          }
          if (accessToken !== undefined) {
            console.log('success', accessToken)
            dispatch(setAccessToken(accessToken))
            navigate('/organizations')
          }
        } else if (response?.error?.status === HttpResponseStatus.NOT_FOUND) {
          setValidEmail(false)
        } else if (
          response?.error?.status === HttpResponseStatus.UNAUTHORIZED
        ) {
          formik.setErrors({ password: 'Invalid Password' })
        } else if (
          response?.error?.status === HttpResponseStatus.INTERNAL_SERVER_ERROR
        ) {
          dispatch(
            openNotification({
              title: 'Error',
              message: 'Internal Server Error',
              type: 'error',
              show: true,
            })
          )
        }
      } catch (e: any) {
        console.log('Error on Submiting form', e)
      }
    },
  })

  useEffect(() => {
    if (magicLinkexpired) {
      dispatch(
        openNotification({
          title: 'Magic link expired',
          message:
            'Enter your email address and click Next. Then, click Get Magic Link to send yourself a new magic link.',
          type: 'warning',
          show: true,
        })
      )
    }
  }, [magicLinkexpired])

  const handleNext = (): void => {
    setStep('password')
  }

  const toggleShowPassword = (): void => {
    setShowPassword((prevState) => !prevState)
  }

  const startOver = (): void => {
    formik.resetForm()
    setStep('email')
    setValidEmail(true)
  }

  const goToRegister = (): void => {
    formik.resetForm()
    navigate('/register')
  }

  const handleRequestMagicLink = async (): Promise<void> => {
    try {
      const { email } = formik.values
      const response = (await getMagicLink({ email, redirectTo: '' })) as {
        data: { status: number }
      }

      if (response?.data?.status >= 200 && response?.data?.status <= 226) {
        setStep('magicLinkSent')
      } else {
        setValidEmail(false)
      }
    } catch (e: any) {
      console.log('error on handleRequestMagicLink', e)
    }
  }

  const isInValidEmail = (
    value: string,
    errors: string | undefined
  ): boolean => {
    return value === '' || errors !== undefined
  }

  const loggedIn = useSelector((state: RootState) => state.session.loggedIn)

  useEffect(() => {
    if (loggedIn) {
      navigate('/')
    }
  }, [loggedIn])

  const handleNextOnEnter = async (): Promise<void> => {
    if (!isInValidEmail(formik.values.email, formik.errors.email)) {
      try {
        handleNext()
        // const { email } = formik.values
        // const response = (await getSendMagicLink(email)) as {
        //   data: { sendMagicLink: boolean }
        // }
        // // if the user does not have a password, send them a magic link
        // if (response?.data?.sendMagicLink) {
        //   await handleRequestMagicLink()
        // } else {
        //   handleNext()
        // }
      } catch (e: any) {
        console.log('error on handleNextOnEnter', e)
      }
    }
  }

  const handleSubmitOnEnter = (): void => {
    formik.handleSubmit()
  }

  return (
    <>
      <Notification {...notification} />
      <div className="flex flex-col md:flex-row h-screen bg-offWhite-light">
        <div className="bg-brand-gradient flex align-center px-10 pb-9 md:px-20 md:w-1/2 flex-col justify-center">
          <img
            alt="factor logo"
            className="max-w-[154px] md:max-w-[250px] mb-8 mt-9 md:mt-0 md:mb-20 m-auto md:m-0"
            src={Logo}
          />
          <Headline className="text-offBlack-dark leading-normal m-auto md:m-0">
            Factor AI training platform
          </Headline>
        </div>
        <div className="flex flex-col justify-center px-10 md:px-20 md:w-1/2 py-10">
          <h2 className="mb-10 text-xl">Log in to Factor</h2>
          {step === 'email' ? (
            <>
              <FormField
                className="mb-2"
                label="Email address"
                onChange={formik.handleChange}
                inputName="email"
                defaultValue={formik.values.email}
                onSubmit={handleNextOnEnter}
                cySelector="login-email"
                touched={formik.touched.email}
                setTouched={formik.setFieldTouched}
                hint={'Please enter a valid email address...'}
                success={
                  !isInValidEmail(formik.values.email, formik.errors.email)
                    ? 'Email address validated!'
                    : null
                }
              />
              <div className="flex justify-end">
                <Button
                  className="w-1/4"
                  onClick={handleNextOnEnter}
                  cySelector="login-next"
                  disabled={isInValidEmail(
                    formik.values.email,
                    formik.errors.email
                  )}
                >
                  Next
                </Button>
              </div>
            </>
          ) : null}
          {step === 'password' ? (
            <>
              <FormField
                className="mb-2"
                label="Password"
                onChange={formik.handleChange}
                onSubmit={handleSubmitOnEnter}
                touched={formik.touched.password}
                cySelector="login-password"
                setTouched={formik.setFieldTouched}
                error={
                  formik.touched.password === true ||
                  formik.errors.password === ''
                    ? formik.errors.password
                    : null
                }
                icon={
                  showPassword ? (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      onClick={toggleShowPassword}
                      icon={light('eye')}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      onClick={toggleShowPassword}
                      icon={light('eye-slash')}
                    />
                  )
                }
                type={showPassword ? 'text' : 'password'}
                inputName="password"
                defaultValue={formik.values.password}
              />
              <div className="flex justify-between">
                <Button
                  style="gradient"
                  className="w-max"
                  onClick={handleRequestMagicLink}
                >
                  <FontAwesomeIcon icon={light('wand-magic-sparkles')} />
                  Get magic link
                </Button>
                <Button
                  className="w-1/4"
                  disabled={formik.values.password.length === 0 || !validEmail}
                  onClick={formik.handleSubmit}
                  cySelector="login-submit"
                  loading={formik.isSubmitting}
                  name="login"
                >
                  Log In
                </Button>
              </div>
              {!validEmail && (
                <div className={'mt-8 text-sm text-red-dark'}>
                  <div>
                    We couldn’t find a Factor account matching your email
                    address.
                  </div>
                  <div>
                    Be sure to{' '}
                    <button
                      onClick={goToRegister}
                      className={'underline underline-offset-2 cursor-pointer'}
                    >
                      register an account
                    </button>
                    , or{' '}
                    <button
                      onClick={startOver}
                      className={'underline underline-offset-2 cursor-pointer'}
                    >
                      try entering your email again.
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : null}
          {step === 'magicLinkSent' ? (
            <Headline size={'sm'}>
              We’ve sent a magic link to your email! Click that link to access
              Factor.
            </Headline>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Login
