import { createApi } from '@reduxjs/toolkit/query/react'

import { type AiConfigItemUpdate, type AiConfigItemCreate } from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiConfigItemSlice = createApi({
  reducerPath: 'aiConfigItemApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiConfigItem', 'aiConfigItemList'],
  endpoints: (builder) => ({
    getAiConfigItemList: builder.query({
      query: ({
        aiConfigSid,
        sortOrder,
        pageToken,
      }: {
        aiConfigSid?: string
        sortOrder?: string
        pageToken?: string
      }) => ({
        url: `/v1/AiConfigItems`,
        params: {
          aiConfigSid,
          sortOrder,
          pageToken,
        },
      }),
      providesTags: ['aiConfigItemList'],
    }),
    getAiConfigItem: builder.query({
      query: (sid: string) => `/v1/AiConfigItems/${sid}`,
      providesTags: ['aiConfigItem'],
    }),
    createAiConfigItem: builder.mutation({
      query: (body: AiConfigItemCreate) => ({
        url: `/v1/AiConfigItems/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiConfigItemList'],
    }),
    updateAiConfigItem: builder.mutation({
      query: (body: AiConfigItemUpdate) => ({
        url: `/v1/AiConfigItems/${body.sid}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiConfigItemList'],
    }),
    deleteAiConfigItem: builder.mutation({
      query: (sid: string) => ({
        url: `/v1/AiConfigItems/${sid}`,
        method: 'DELETE',
        responseHandler: async (response): Promise<{ status: number }> => {
          return { status: response.status }
        },
      }),
    }),
  }),
})

export const {
  useLazyGetAiConfigItemListQuery,
  useGetAiConfigItemQuery,
  useCreateAiConfigItemMutation,
  useUpdateAiConfigItemMutation,
  useDeleteAiConfigItemMutation,
} = apiAiConfigItemSlice
