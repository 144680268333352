import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { type MouseEvent, useState } from 'react'
import ReactJson from 'react-json-view'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Headline, TextArea } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {
  useGetAiParseQuery,
  useLazyDownloadQuery,
  useUpdateAiParseMutation,
} from '../../../services/aiparses/AiParseService'
import { useAppDispatch } from '../../../store'
import { openNotification } from '../../../store/notifications'
import { type AiParseUpdate } from '../../../types'
import { downloadFile } from '../../../utils/downloadFile/downloadFile'
import { contentToJson, isValidJson } from '../../../utils/json/jsonParser'
import { getSidPath } from '../../../utils/sids/sids'

const STATES = {
  PENDING: 'pending',
  SENDING: 'sending',
  DONE: 'done',
  FAILED: 'failed',
  INVALID: 'invalid',
}

const AiParse = (): JSX.Element => {
  const { aiParseSid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [parseVerified, setParseVerified] = useState<any>(null)
  const [correctedParse, setCorrectedParse] = useState<any>(null)
  const [updated, setUpdated] = useState(false)
  const [edit, setEdit] = useState(false)
  const [jsonValid, setJsonValid] = useState(false)
  const { data: aiParse, isLoading } = useGetAiParseQuery(aiParseSid as string)
  const [downloadContent] = useLazyDownloadQuery()
  const [state, setState] = useState<any>(aiParse?.state || 'null')
  const [updateAiParse] = useUpdateAiParseMutation()

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleDownloadContent = async (): Promise<void> => {
    const response = await downloadContent(aiParse.sid).unwrap()
    downloadFile(aiParse.sid, undefined, response.url)
  }

  const handleOpenInputData = (event: MouseEvent): void => {
    if (event.metaKey) {
      window.open(
        `/aiparseinputs?aiParseSid=${aiParse.sid}`,
        '_blank',
        'noreferer'
      )
    } else {
      navigate(`/aiparseinputs?aiParseSid=${aiParse.sid}`)
    }
  }

  const handleOpen = (event: MouseEvent): void => {
    const path = getSidPath(aiParse.referenceSid)
    if (path === 'Unknown') return
    if (event.metaKey) {
      window.open(path, '_blank', 'noreferer')
    } else {
      navigate(path)
    }
  }

  const initialVerified =
    aiParse.humanVerifiedParseResult !== null
      ? aiParse.humanVerifiedParseResult.toString()
      : 'null'
  const initialCorrectedParse =
    aiParse.correctedParsingResult !== null
      ? aiParse.correctedParsingResult
      : '{}'

  if (parseVerified === null) {
    setParseVerified(initialVerified)
  }
  if (correctedParse === null) {
    setCorrectedParse(initialCorrectedParse)
  }

  function changeParseVerified(event: any): void {
    setParseVerified(event.target.value)
    handleEnablingSave(event.target.value, correctedParse, jsonValid, state)
  }

  function changeState(event: any): void {
    setState(event.target.value)
    handleEnablingSave(event.target.value, correctedParse, jsonValid, state)
  }

  const handleCorrectedParse = (value: string): void => {
    setCorrectedParse(value)
    const isValid = isValidJson(value)
    setJsonValid(!isValid)
    handleEnablingSave(parseVerified, value, isValid, state)
  }

  const handleEnablingSave = (
    parseVerifiedValue: string | null,
    correctedParseValue: string,
    isJsonValid: boolean,
    stateValue: string
  ): void => {
    setUpdated(
      (initialCorrectedParse !== correctedParseValue && isJsonValid) ||
        initialVerified !== parseVerifiedValue ||
        aiParse?.state !== stateValue
    )
  }

  const handleUpdateAiParse = async (): Promise<void> => {
    try {
      const body: AiParseUpdate = {
        sid: aiParse.sid,
        parsedDate: aiParse?.parsedDate,
        humanVerifiedParseResult:
          parseVerified === 'null' ? null : parseVerified === 'true',
      }
      if (
        correctedParse !== '{}' &&
        aiParse.correctedParsingResult !== correctedParse
      ) {
        body.correctedParsingResult = correctedParse
      }
      if (state !== 'null' && aiParse.state !== state) {
        body.state = state
      }

      const response = (await updateAiParse(body)) as { data: any; error: any }

      if (response?.error !== undefined) {
        dispatch(
          openNotification({
            title: 'Error',
            message: response.error.data.message,
            type: 'error',
            show: true,
          })
        )
      } else {
        setUpdated(false)
        setEdit(false)
        dispatch(
          openNotification({
            title: 'Update Successful',
            message: 'AiParse ' + aiParseSid + ' was updated',
            type: 'success',
            show: true,
          })
        )
      }
    } catch (e) {
      dispatch(
        openNotification({
          title: 'Error',
          message: 'Internal Server Error',
          type: 'error',
          show: true,
        })
      )
    }
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Ai Parses - {aiParseSid}
          </Headline>
        </TopSection>
      </div>
      <div className="text-offBlack-ligth font- text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <table className="w-[100%]">
              <tbody>
                <tr>
                  <td>
                    <span>Created: {aiParse.created}</span>
                  </td>
                  <td>
                    <span>Last Updated: {aiParse.updated}</span>
                  </td>
                  <td>
                    <span>Parsed Date: {aiParse.parsedDate}</span>
                  </td>
                  <td>
                    <span>Version: {aiParse.version}</span>
                  </td>
                </tr>
                <tr>
                  <td className="py-3">
                    <span>Model: {aiParse.model}</span>
                  </td>
                  <td>
                    <span>State: </span>
                    <select
                      defaultValue={aiParse.state}
                      onChange={changeState}
                      className="text-offBlack-dark font-mono text-xs "
                    >
                      <option value={STATES.DONE}>{STATES.DONE}</option>
                      <option value={STATES.PENDING}>{STATES.PENDING}</option>
                      <option value={STATES.SENDING}>{STATES.SENDING}</option>
                      <option value={STATES.FAILED}>{STATES.FAILED}</option>
                      <option value={STATES.INVALID}>{STATES.INVALID}</option>
                    </select>
                  </td>
                  <td>
                    {/* TODO not available yet
                                    <span>Tokens Used: {aiParse.tokensUsed}</span>
                                     */}
                  </td>
                  <td>
                    <div className="inline-flex items-center">
                      <span>Parse Verified: </span>
                      <div className="p-1">
                        <select
                          defaultValue={parseVerified}
                          onChange={changeParseVerified}
                          className="text-offBlack-dark font-mono text-xs "
                        >
                          <option value={'null'} />
                          <option value={'true'}>true</option>
                          <option value={'false'}>false</option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr hidden={aiParse?.referenceSid === null}>
                  <td>
                    <div>
                      Reference Sid:{' '}
                      <span
                        className={`px-2 ${
                          getSidPath(aiParse.referenceSid) ===
                          'Unknown'
                            ? ''
                            : 'text-blue-700 underline cursor-pointer'
                        } `}
                        onClick={handleOpen}
                      >
                        {aiParse.referenceSid}
                      </span>
                    </div>
                  </td>
                  <td>
                    {aiParse.s3Bucket !== null && aiParse.s3Key !== null && (
                      <div>
                        S3 Bucket:{' '}
                        <span
                          onClick={handleDownloadContent}
                          className={`px-2 text-blue-700 underline cursor-pointer`}
                        >
                          s3://{aiParse.s3Bucket}/{aiParse.s3Key}
                        </span>
                      </div>
                    )}
                    {aiParse.s3Bucket === null && aiParse.s3Key === null && (
                      <div
                        className={`px-2 text-blue-700 underline cursor-pointer`}
                        onClick={handleOpenInputData}
                      >
                        View input data
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>
        <div className="py-1">
          <Card title="Prompt">
            <div className="text-xs text-justify">{aiParse.prompt}</div>
          </Card>
        </div>
        {/* TODO not available yet
                    <div className="py-1">
                    <Card title="Input">
                        <div className="font-semibold text-xs text-justify">
                            {aiParse.input}
                        </div>
                    </Card>
                    </div>
                  */}
        <div className="py-1">
          <Card title="Parsing Result">
            <div className="whitespace-pre-line ">
              <ReactJson
                collapseStringsAfterLength={150}
                src={
                  aiParse?.parsingResult !== null
                    ? contentToJson(aiParse.parsingResult)
                    : JSON.parse('{}')
                }
                name={null}
                displayDataTypes={false}
                theme="rjv-default"
              />
            </div>
          </Card>
        </div>
        <div className="py-1">
          <Card title="Corrected Parsing Result">
            <div className="whitespace-pre-line" hidden={edit}>
              <ReactJson
                collapseStringsAfterLength={150}
                src={
                  aiParse?.correctedParsingResult !== null
                    ? contentToJson(aiParse.correctedParsingResult)
                    : JSON.parse('{}')
                }
                name={null}
                displayDataTypes={false}
                theme="rjv-default"
              />
              <div className="pt-5">
                <Button
                  onClick={() => {
                    setEdit(true)
                    handleEnablingSave(
                      parseVerified,
                      correctedParse,
                      !jsonValid,
                      state
                    )
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
            <div hidden={!edit} className="w-[100%]">
              <TextArea
                rows={25}
                onChange={(value): void => {
                  handleCorrectedParse(value)
                }}
                defaultValue={correctedParse}
              ></TextArea>
            </div>
            <div className="w-[100%] flex flex-row-reverse">
              <div className="self-end pt-4 px-3 inline-flex">
                <div hidden={!jsonValid}>
                  <button
                    className={clsx(
                      'rounded-full h-7 w-7 p-[5px] flex justify-center',
                      {
                        'bg-yellow-400 shadow-base': true,
                      }
                    )}
                  >
                    <FontAwesomeIcon icon={light('warning')} color={'white'} />
                  </button>
                </div>
                <div hidden={!jsonValid} className="p-1 text-yellow-400">
                  <p>JSON is invalid</p>
                </div>
              </div>
            </div>
            <div className="w-[100%] flex flex-row-reverse">
              <div hidden={!edit} className="self-end pt-4 px-3">
                <Button
                  onClick={() => {
                    setEdit(false)
                    handleEnablingSave(
                      parseVerified,
                      initialCorrectedParse,
                      !jsonValid,
                      state
                    )
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div className="w-[100%] flex flex-row-reverse">
          <div className="w-[100%] flex flex-row-reverse">
            <div className="self-end pt-4 px-3">
              <div>
                <Button
                  disabled={!updated}
                  onClick={() => {
                    handleUpdateAiParse()
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AiParse
