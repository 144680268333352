import { useFormik } from 'formik'
import { type FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCreateAiPromptMutation } from 'services/aiprompt/AiPromptService'

import {Button, Slideover, TextArea} from '../components'
import { slideOverSelector } from '../store/selectors'
import { closeSlideOver } from '../store/slideOvers'
import {useNavigate} from "react-router-dom";

export const CreateAiPrompt: FC = () => {
  const { name, data } = useSelector(slideOverSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [createAiPrompt] = useCreateAiPromptMutation()
  const formik = useFormik({
    initialValues: {
      prompt: data,
    },
    onSubmit: async (values) => {
      try {
        await createAiPrompt({ prompt: values.prompt })
        dispatch(closeSlideOver())
        navigate(`/aiprompt`)
      } catch (error) {
        console.error(error)
      }
    },
  })

  const show = name === 'CreateAiPrompt'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  return (
    <Slideover open={show} onClose={handleClose} title={`New AI Prompt`}>
      <div>
        <div className="text-sm mb-4 block">
          <h3>Prompt:</h3>
        </div>

        <div className="text-offBlack-dark text-sm block">
          <TextArea
              rows={25}
              defaultValue={formik.values.prompt}
              onChange={(value): void => {
                formik.setFieldValue('prompt', value)
              }}
          ></TextArea>
        </div>

        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={formik.isSubmitting} onClick={formik.handleSubmit}>
            Create
          </Button>
        </div>
      </div>
    </Slideover>
  )
}
