import { createApi } from '@reduxjs/toolkit/query/react'

import {type AiParseReviewUpdate} from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiParseReviewSlice = createApi({
  reducerPath: 'aiParseReviews',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiParseReview', 'aiParseReviewList'],
  endpoints: (builder) => ({
    getAiParseReviewList: builder.query({
      query: ({
        state,
        reviewed,
        organizationSid,
        referenceSid,
        sortOrder,
        pageToken,
        pageSize
      }: {
        reviewed?: string
        organizationSid?: string
        referenceSid?: string
        state?: string
        sortOrder?: string
        pageToken?: string
        pageSize?: string
      }) => ({
        url: `/v1/AiParseReviews`,
        params: {
          reviewed,
          organizationSid,
          referenceSid,
          state,
          sortOrder,
          pageToken,
          pageSize
        },
      }),
      providesTags: ['aiParseReviewList'],
    }),
    getAiParseReview: builder.query({
      query: (sid: string) => `/v1/AiParseReviews/${sid}`,
      providesTags: ['aiParseReview'],
    }),
    updateAiParseReview: builder.mutation({
      query: (body: AiParseReviewUpdate) => ({
        url: `/v1/AiParseReviews/${body.sid}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiParseReview'],
    }),
  }),
})

export const { useGetAiParseReviewListQuery, useLazyGetAiParseReviewListQuery, useGetAiParseReviewQuery, useUpdateAiParseReviewMutation } =
  apiAiParseReviewSlice
