import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'

// Services
import { apiAiParseReviewSlice } from '../services/aiparsereviews/AiParseReviewService'
import { apiAiParseSlice } from '../services/aiparses/AiParseService'
import { apiAiParseInputSlice } from '../services/aiparseinputs/AiParseInputService'
import { apiAuthSlice } from '../services/auth/Auth'
import { apiInboundEmailSlice } from '../services/inboundemails/InboundEmailService'
import { apiOrganizationsSlice } from '../services/organizations/Organizations'
import { apiUserSlice } from '../services/user/User'
import { apiDownloadSlice } from '../services/downloads/DownloadService'
import {apiEventRelationSlice} from "../services/eventrelations/EventRelationService";
import {apiEventSlice} from "../services/events/EventService";
import {apiAiPromptSlice} from "../services/aiprompt/AiPromptService";
import {apiAiConfigTestSlice} from "../services/aiconfigtest/AiConfigTestService";
import {apiAiConfigSlice} from "../services/aiconfigs/AiConfigService";
import {apiAiConfigItemSlice} from "../services/aiconfigitems/AiConfigItemService";

// Reducers
import notificationsReducer from './notifications'
import sessionReducer from './session'
import slideOversReducer from './slideOvers'

const middlewares = [
  apiAuthSlice.middleware,
  apiUserSlice.middleware,
  apiOrganizationsSlice.middleware,
  apiInboundEmailSlice.middleware,
  apiAiParseSlice.middleware,
  apiAiParseReviewSlice.middleware,
  apiAiParseInputSlice.middleware,
  apiDownloadSlice.middleware,
  apiEventRelationSlice.middleware,
  apiEventSlice.middleware,
  apiAiPromptSlice.middleware,
  apiAiConfigTestSlice.middleware,
  apiAiConfigSlice.middleware,
  apiAiConfigItemSlice.middleware,
]

const rootReducer = combineReducers({
  session: sessionReducer,
  slideOvers: slideOversReducer,
  notifications: notificationsReducer,
  [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
  [apiUserSlice.reducerPath]: apiUserSlice.reducer,
  [apiOrganizationsSlice.reducerPath]: apiOrganizationsSlice.reducer,
  [apiInboundEmailSlice.reducerPath]: apiInboundEmailSlice.reducer,
  [apiAiParseSlice.reducerPath]: apiAiParseSlice.reducer,
  [apiAiParseReviewSlice.reducerPath]: apiAiParseReviewSlice.reducer,
  [apiAiParseInputSlice.reducerPath]: apiAiParseInputSlice.reducer,
  [apiDownloadSlice.reducerPath]: apiDownloadSlice.reducer,
  [apiEventRelationSlice.reducerPath]: apiEventRelationSlice.reducer,
  [apiEventSlice.reducerPath]: apiEventSlice.reducer,
  [apiAiPromptSlice.reducerPath]: apiAiPromptSlice.reducer,
  [apiAiConfigTestSlice.reducerPath]: apiAiConfigTestSlice.reducer,
  [apiAiConfigSlice.reducerPath]: apiAiConfigSlice.reducer,
  [apiAiConfigItemSlice.reducerPath]: apiAiConfigItemSlice.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>): any =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([...middlewares]),
  })

export const store: any = setupStore()

setupListeners(store.dispatch)
// Infer the `RootState` and `AppDigspatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
