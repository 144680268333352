import { createApi } from '@reduxjs/toolkit/query/react'

import { type AiPromptCreate } from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiPromptSlice = createApi({
  reducerPath: 'aiPromptApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiPrompt', 'aiPromptList'],
  endpoints: (builder) => ({
    getAiPromptList: builder.query({
      query: ({
        organizationSid,
        referenceSid,
        humanVerifiedParseResult,
        promptLike,
        sortOrder,
        pageToken,
      }: {
        organizationSid?: string
        referenceSid?: string
        humanVerifiedParseResult?: string
        sortOrder?: string
        pageToken?: string
        promptLike?: string
      }) => ({
        url: `/v1/AiPrompts`,
        params: {
          organizationSid,
          referenceSid,
          promptLike,
          sortOrder,
          humanVerifiedParseResult,
          pageToken,
        },
      }),
      providesTags: ['aiPromptList'],
    }),
    getAiPrompt: builder.query({
      query: (sid: string) => `/v1/AiPrompts/${sid}`,
      providesTags: ['aiPrompt'],
    }),
    createAiPrompt: builder.mutation({
      query: (body: AiPromptCreate) => ({
        url: `/v1/AiPrompts/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiPromptList'],
    }),
  }),
})

export const {
  useLazyGetAiPromptListQuery,
  useGetAiPromptQuery,
  useCreateAiPromptMutation,
} = apiAiPromptSlice
