import clsx from 'clsx'
import { type FC, type ReactNode } from 'react'

interface CardProps {
  title?: string
  children: ReactNode
  className?: string
}

export const Card: FC<CardProps> = ({ title, children, className }) => {
  return (
    <div
      className={clsx(className, 'p-5 bg-white shadow-base rounded-lg w-full')}
    >
      {title ? (
        <div className="font-mono text-offBlack-light text-[11px] uppercase">
          {title}
        </div>
      ) : null}
      {children}
    </div>
  )
}
