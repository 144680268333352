import { useFormik } from 'formik'
import { type FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCreateAiConfigMutation } from 'services/aiconfigs/AiConfigService'

import {Button, Slideover, TextArea} from '../components'
import { slideOverSelector } from '../store/selectors'
import { closeSlideOver } from '../store/slideOvers'
import {useNavigate} from "react-router-dom";
import {type AiConfig } from 'types'
import { AI_CONFIG } from 'routing/paths'

export const CreateAiConfig: FC = () => {
  const { name, data } = useSelector(slideOverSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [createAiConfig] = useCreateAiConfigMutation()
  const formik = useFormik({
    initialValues: {
      title: data,
    },
    onSubmit: async (values) => {
      try {
        const payload = values.title === '' ? {} : { title: values.title }
        const aiConfig: AiConfig = await createAiConfig(payload).unwrap()
        dispatch(closeSlideOver())
        navigate(`${AI_CONFIG}/${aiConfig.sid}`)
      } catch (error) {
        console.error(error)
      }
    },
  })

  const show = name === 'CreateAiConfig'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  return (
    <Slideover open={show} onClose={handleClose} title={`New AI Config`}>
      <div>
        <div className="text-sm mb-4 block">
          <h3>Title:</h3>
        </div>

        <div className="text-offBlack-dark text-sm block">
          <TextArea
              rows={5}
              defaultValue={formik.values.title}
              onChange={(value): void => {
                formik.setFieldValue('title', value)
              }}
              maxLength={191}
          ></TextArea>
        </div>

        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={formik.isSubmitting} onClick={formik.handleSubmit}>
            Create
          </Button>
        </div>
      </div>
    </Slideover>
  )
}
