
import {useNavigate, useParams} from 'react-router-dom'

import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {useGetAiConfigTestQuery} from "../../../services/aiconfigtest/AiConfigTestService";
import {Button, Headline} from "../../../components";
import {openSlideOver} from "../../../store/slideOvers";
import {useDispatch} from "react-redux";

const AiConfigTest = (): JSX.Element => {
  const { aiConfigTestSid } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data: aiConfigTest, isLoading } = useGetAiConfigTestQuery(
    aiConfigTestSid as string
  )

  if (isLoading || aiConfigTest === undefined) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Config Test - {aiConfigTestSid}</Headline>
            <div>
              <Button
                  style="gradient"
                  className="ml-4"
                  onClick={() => {
                    dispatch(
                        openSlideOver({
                          name: 'CreateAiConfigTest',
                          data: {
                            AiConfigSid: aiConfigTest.aiConfigSid,
                            AiParseSids: aiConfigTest.aiParseSids
                          }
                        })
                    )
                  }}
              >
                Create AI Config Test
              </Button>
            </div>
          </div>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <div className="max-h-[500px] overflow-scroll">
              <table className="font-semibold w-[100%]">
                <tbody>
                  <tr>
                    <td className="py-3">
                      <span>Created: {aiConfigTest.created}</span>
                    </td>
                    <td>
                      <span>Last Updated: {aiConfigTest.updated}</span>
                    </td>
                    <td>
                      <span>Version: {aiConfigTest.version}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>State: {aiConfigTest.state}</span>
                    </td>
                    <td>
                      <span>Output Accuracy: {aiConfigTest?.outputAccuracy}</span>
                    </td>
                    <td>
                      <span>Tests: {aiConfigTest?.tests}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3">
                      <span>Correct: {aiConfigTest?.correct}</span>
                    </td>
                    <td>
                      <span>Incorrect: {aiConfigTest?.incorrect}</span>
                    </td>
                    <td>
                      <span>Failed: {aiConfigTest?.failed}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <div>
                  <span>AI Config Sid: {aiConfigTest?.aiConfigSid}</span>
                </div>
                <div className="py-3">
                  <span>AI Parse Sids: {
                    aiConfigTest?.aiParseSids?.map((aiParseSid: string) => (
                        <div className="py-1" key={aiParseSid}>
                          <span
                              className={`px-2 text-blue-700 underline cursor-pointer`}
                                onClick={(event) => {
                            if (event.ctrlKey || event.metaKey) {
                              window.open(`/aiparses/${aiParseSid}`, '_blank', 'noreferer')
                            } else {
                              navigate(`/aiparses/${aiParseSid}`)
                            }
                          }}>
                            {aiParseSid}
                          </span>
                        </div>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
export default AiConfigTest
