import { Button } from '../Button/Button'
import {Card} from "../Card/Card";

export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
}

interface Filter {
  label: string
  attribute: string
  value?: any
  type: FilterType
  options?: string[]
}

interface TableFiltersProps {
  filters: Filter[]
  searchParams: Record<string, string | undefined>
  setSearchParams: (params: Record<string, string | undefined>) => void
  handleSearch: () => Promise<void>
}

export const TableFilters = ({
  filters,
  setSearchParams,
  searchParams,
  handleSearch,
}: TableFiltersProps): JSX.Element => {
  return (
    <div>
      <div className="pt-5 px-10">
        <Card title="Filters">
        <div className="flex gap-5">
          {filters.map((filter) => {
            return (
              <div key={filter.attribute}>
                <label className="text-offBlack-dark pr-3 text-xs">{filter.label}</label>
                {filter.type === 'text' ? (
                  <input
                    type="text"
                    className="text-xs"
                    defaultValue={filter.value}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        const { [filter.attribute]: _, ...rest } = searchParams
                        setSearchParams(rest)
                      } else {
                        setSearchParams({
                          ...searchParams,
                          [filter.attribute]: e.target.value,
                        })
                      }
                    }}
                  />
                ) : filter.type === 'date' ? (
                  <input
                    type="date"
                    className="text-xs"
                    defaultValue={filter.value}
                    onChange={(e) => {
                      setSearchParams({
                        ...searchParams,
                        [filter.attribute]: e.target.value,
                      })
                    }}
                  />
                ) : filter.type === 'select' ? (
                  <select
                    className="text-xs"
                    defaultValue={filter.value}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        const { [filter.attribute]: _, ...rest } = searchParams
                        setSearchParams(rest)
                      } else {
                        setSearchParams({
                          ...searchParams,
                          [filter.attribute]: e.target.value,
                        })
                      }
                    }}
                  >
                    <option value=""></option>
                    {filter.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : null}
              </div>
            )
          })}
          <div className="pl-5">
            <Button
              style="primary"
              onClick={() => {
                handleSearch()
              }}
            >
              Search
            </Button>
          </div>
        </div>
        </Card>
      </div>
    </div>
  )
}
