import { createApi } from '@reduxjs/toolkit/query/react'

import { type AiConfigUpdate, type AiConfigCreate } from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiConfigSlice = createApi({
  reducerPath: 'aiConfigApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiConfig', 'aiConfigList'],
  endpoints: (builder) => ({
    getAiConfigList: builder.query({
      query: ({
        state,
        sortOrder,
        pageToken,
      }: {
        state?: string
        sortOrder?: string
        pageToken?: string
      }) => ({
        url: `/v1/AiConfigs`,
        params: {
          state,
          sortOrder,
          pageToken,
        },
      }),
      providesTags: ['aiConfigList'],
    }),
    getAiConfig: builder.query({
      query: (sid: string) => `/v1/AiConfigs/${sid}`,
      providesTags: ['aiConfig'],
    }),
    createAiConfig: builder.mutation({
      query: (body: AiConfigCreate) => ({
        url: `/v1/AiConfigs/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiConfigList'],
    }),
    updateAiConfig: builder.mutation({
      query: (body: AiConfigUpdate) => ({
        url: `/v1/AiConfigs/${body.sid}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiConfigList'],
    }),
  }),
})

export const {
  useLazyGetAiConfigListQuery,
  useGetAiConfigQuery,
  useCreateAiConfigMutation,
  useUpdateAiConfigMutation,
} = apiAiConfigSlice
