interface urlEnv {
  production: string
  staging: string
  gov: string
  local: string
}

export const apiEnv: urlEnv = {
  production: 'https://phoenix-api.factor.io',
  staging: 'https://phoenix-api.staging.factor.io',
  gov: 'https://api.gov.factor.io',
  local: '/',
}
