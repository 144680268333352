import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'

interface TableProps {
  data: any[]
  headers: Array<{
    label: string
    attribute: string
    link?: boolean
    handleSort?: () => any
    sortDirection?: 'Asc' | 'Desc'
    overFlow?: boolean
    tooltips?: string[]
  }>
}

export const Table = ({ data, headers }: TableProps): JSX.Element => {
  return (
    <table className="min-w-full divide-y divide-offWhite-mid border-spacing-y-1 overflow-hidden border-dashed">
      <thead>
        <tr className="border-gray-400 border-b-[1px] bg-gray-200" role="list">
          {headers?.map((header) => (
            <TableHeader
              handleSort={header.handleSort}
              sortDirection={header.sortDirection}
              link={header.link}
              key={`header-${header.attribute}`}
              tooltips={header.tooltips}
            >
              {header.label}
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr className="hover:bg-sky-200" key={row.sid}>
            {headers.map((header) => (
              <TableRow
                overFlow={header.overFlow}
                link={header.link}
                handleClick={row[header.attribute]?.handleClick}
                key={`${row.sid}-${header.attribute}`}
              >
                {row[header.attribute]?.value}
              </TableRow>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
