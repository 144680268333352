import clsx from 'clsx'
import { type ReactNode } from 'react'
import { Grid } from 'react-loader-spinner'

export interface ButtonProps {
  children: ReactNode
  className?: string
  style?: 'primary' | 'gradient' | 'secondary' | 'black-outline'
  onClick?: (() => void) | (() => Promise<void>)
  disabled?: boolean
  loading?: boolean
  id?: string | undefined
  cySelector?: string
  type?: 'submit' | 'reset' | 'button'
  name?: string
}

export const Button = ({
  children,
  className = '',
  style = 'primary',
  onClick,
  disabled = false,
  loading = false,
  id = undefined,
  cySelector = '',
  type = 'button',
  name = '',
}: ButtonProps): JSX.Element => {
  const accent = {
    gradient: 'bg-brand-gradient text-offBlack-dark',
    primary: 'bg-blue-500 text-white',
    secondary: 'bg-white text-offBlack-dark',
    'black-outline':
      'bg-transparent text-offBlack-dark border border-offWhite-light text-offWhite-light',
  }
  const handleClick = async (): Promise<void> => {
    await onClick?.()
  }

  return (
    <button
      name={name}
      type={type}
      data-cy={cySelector}
      onClick={handleClick}
      disabled={disabled || loading}
      id={id}
      className={clsx(
        'flex justify-center font-semibold font-sans px-5 py-2 rounded-3xl text-sm shadow-sm antialised',
        { 'bg-offBlack-lightest text-offBlack-light': disabled },
        { [accent[style]]: !disabled },
        className
      )}
    >
      {loading ? (
        <span className="px-5">
          <Grid
            height={20}
            width={20}
            color="#e2e2e2"
            wrapperStyle={{}}
            visible={true}
            ariaLabel="oval-loading"
          />
        </span>
      ) : (
        <span className="place-self-center">{children}</span>
      )}
    </button>
  )
}
