import { createApi } from '@reduxjs/toolkit/query/react'

import baseQuery from '../baseQuery'

export const apiUserSlice = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getLoggedInUser: builder.query({
      query: () => '/api/user',
    }),
  }),
})

export const { useGetLoggedInUserQuery } = apiUserSlice
