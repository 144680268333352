
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Headline, TextArea } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useGetAiConfigQuery, useUpdateAiConfigMutation } from "../../../services/aiconfigs/AiConfigService"
import { useState } from 'react'
import { type AiConfigUpdate } from 'types'
import { openNotification } from 'store/notifications'
import { useAppDispatch } from 'store'
import { AI_CONFIG_ITEMS } from 'routing/paths'

const AiConfig = (): JSX.Element => {
  const { aiConfigSid } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [aiConfigState, setAiConfigState] = useState('null')
  const [aiConfigTitle, setAiConfigTitle] = useState('null')
  const [aiConfigVersion, setAiConfigVersion] = useState(0)
  const [aiConfigUpdated, setAiConfigUpdated] = useState('null')
  const [shouldSave, setShouldSave] = useState(false)
  const [updateAiConfig] = useUpdateAiConfigMutation()

  const { data: aiConfig, isLoading } = useGetAiConfigQuery(
    aiConfigSid as string
  )

  if (isLoading || aiConfig === undefined) {
    return <div>Loading...</div>
  }

  if (aiConfigState === 'null') {
    setAiConfigState(aiConfig.state)
  }

  if (aiConfigTitle === 'null') {
    setAiConfigTitle(aiConfig.title)
  }

  if (aiConfigVersion === 0) {
    setAiConfigVersion(aiConfig.version)
  }

  if (aiConfigUpdated === 'null') {
    setAiConfigUpdated(aiConfig.updated)
  }

  const handleEnablingSave = (state: string | null, title: string | null): void => {
    setShouldSave(
      aiConfig?.state !== state ||
      aiConfig?.title !== title
    )
  }

  const changeAiConfigState = (event: any): void => {
    setAiConfigState(event.target.value)
    handleEnablingSave(event.target.value, aiConfigTitle)
  }

  const changeAiConfigTitle = (value: any): void => {
    setAiConfigTitle(value)
    handleEnablingSave(aiConfigState, value)
  }

  const handleUpdateAiConfig = async (): Promise<void> => {
    try {
      const body: AiConfigUpdate = {
        sid: aiConfig?.sid,
        currentVersion: aiConfigVersion,
        state: aiConfig?.state !== aiConfigState ? aiConfigState : null,
        title: aiConfig?.title !== aiConfigTitle ? aiConfigTitle : null,
      }
      const updatedAiConfig = await updateAiConfig(body).unwrap()
      setShouldSave(false)
      setAiConfigVersion(updatedAiConfig.version)
      setAiConfigUpdated(updatedAiConfig.updated)
      dispatch(
        openNotification({
          title: 'Update Successful',
          message: 'AI Config ' + aiConfigSid + ' was updated',
          type: 'success',
          show: true,
        })
      )
    } catch (e: any) {
      dispatch(
        openNotification({
          title: 'Error',
          message: e?.data?.message ? e.data.message : 'Internal server error',
          type: 'error',
          show: true,
        })
      )
    }
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Config - {aiConfigSid}</Headline>
            <div>
              <Button
                style="gradient"
                className="ml-4"
                onClick={() => {
                  navigate(`${AI_CONFIG_ITEMS}?aiConfigSid=${aiConfigSid}`)
                }}
              >
                View Items
              </Button>
            </div>
          </div>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <div>
              <table className="font-semibold w-[100%]">
                <tbody>
                  <tr>
                    <td>
                      <span>Created: {aiConfig.created}</span>
                    </td>
                    <td>
                      <span>Last Updated: {aiConfigUpdated}</span>
                    </td>
                    <td>
                      <span>Version: {aiConfigVersion}</span>
                    </td>
                    <td className="py-3">
                      <div className="inline-flex items-center">
                        <span>State: </span>
                        <div className="p-1">
                          <select
                            defaultValue={aiConfigState}
                            onChange={changeAiConfigState}
                            className="text-offBlack-dark font-mono text-xs "
                          >
                            <option value={'unlocked'}>unlocked</option>
                            <option value={'locked'}>locked</option>
                            <option value={'disabled'}>disabled</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td/>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </div>
        <div className="py-1">
          <Card title="Title">
              <TextArea
                  rows={2}
                  defaultValue={aiConfigTitle}
                  onChange={changeAiConfigTitle}
                  maxLength={191}
              ></TextArea>
          </Card>
        </div>
        <div className="w-[100%] flex flex-row-reverse">
          <div className="self-end pt-4 px-3">
            <div>
              <Button
                disabled={!shouldSave}
                onClick={() => {
                  handleUpdateAiConfig()
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AiConfig
