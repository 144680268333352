import { Tooltip as FTooltip } from 'flowbite'
import { type ReactNode, forwardRef, useEffect, useRef } from 'react'

interface TooltipProps {
  children: ReactNode
  position: string
  bgColor?: string
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ children, position, bgColor = 'bg-gray-900' }, ref: any) => {
    const tooltipRef = useRef(null)

    const options = {
      placement: position,
      triggerType: 'hover',
    }

    useEffect(() => {
      if (ref.current !== null) {
        // eslint-disable-next-line no-new
        new FTooltip(tooltipRef.current as any, ref.current, options as any)
      }
    }, [ref])

    return (
      <>
        <div
          ref={tooltipRef}
          id="tooltip-default"
          role="tooltip"
          className={`absolute w-61 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 ${bgColor} rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700`}
        >
          {children}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </>
    )
  }
)

Tooltip.displayName = 'Tooltip'
