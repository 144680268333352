import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useLazyGetInboundEmailListQuery } from '../../../services/inboundemails/InboundEmailService'
import { type InboundEmail } from '../../../types'

const InboundEmailList = (): JSX.Element => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    fromEmail?: string
    toEmails?: string
    ccEmails?: string
    sortOrder?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getInboundEmailList, { data: inboundEmailList, isLoading }] =
    useLazyGetInboundEmailListQuery()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const fromEmail = searchParams.get('fromEmail') ?? undefined
    const toEmails = searchParams.get('toEmails') ?? undefined
    const ccEmails = searchParams.get('ccEmails') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      fromEmail,
      toEmails,
      ccEmails,
      sortOrder,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getInboundEmailList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'From',
        attribute: 'fromEmail',
        value: params.get('fromEmail') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'To',
        attribute: 'toEmails',
        value: params.get('toEmails') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'cc',
        attribute: 'ccEmails',
        value: params.get('ccEmails') ?? undefined,
        type: FilterType.TEXT,
      },
    ]
  }, [params])

  if (isLoading || inboundEmailList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? undefined

    if (sortOrder === undefined) {
      currentSearchParams.set('sortOrder', 'Asc')
    } else if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const disablePrev =
    inboundEmailList?.page === undefined ||
    inboundEmailList?.page?.previousPageToken === null
  const disableNext =
    inboundEmailList?.page === undefined ||
    inboundEmailList?.page?.nextPageToken === null

  const inboundEmailHeaders = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'DATE',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? undefined) as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'FROM', attribute: 'fromEmail', overFlow: true },
    { label: 'TO', attribute: 'toEmails', overFlow: true },
    { label: 'CC', attribute: 'ccEmails', overFlow: true },
    { label: 'CONTENT', attribute: 'content', overFlow: true},
    { label: 'ACTIONS', attribute: 'actions' },
  ]

  const inboundEmailData = inboundEmailList?.inboundEmail?.map(
    (inboundEmail: InboundEmail) => ({
      sid: {
        value: inboundEmail.sid,
        handleClick: (event: KeyboardEvent) => {
          if (event.ctrlKey || event.metaKey) {
            window.open(`/inboundemails/${inboundEmail.sid}`, '_blank', 'noreferer')
          } else {
            navigate(`/inboundemails/${inboundEmail.sid}`)
          }
        },
      },
      fromEmail: { value: inboundEmail.fromEmail },
      toEmails: { value: inboundEmail.toEmails?.join(', ') },
      ccEmails: { value: inboundEmail.ccEmails?.join(', ') },
      created: { value: inboundEmail.created },
      content: { value: inboundEmail.content },
      actions: {
        value:
          inboundEmail.frontUrl !== null ? (
            <div>
              <a
                href={`https://app.frontapp.com/open/${inboundEmail.frontUrl}`}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 hover:text-blue-800 underline"
              >
                Front
              </a>
            </div>
          ) : (
            <div>
              not in front yet
            </div>
          )
      }
    })
  )

  const goNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', inboundEmailList?.page?.nextPageToken)
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(inboundEmailList?.page?.nextPageToken)
  }

  const goPrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', inboundEmailList?.page?.previousPageToken)
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(inboundEmailList?.page?.previousPageToken)
  }

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Inbound Email
          </Headline>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
      </div>
      <div className="p-10">
        <div className="inline-block min-w-full align-middle">
          <Table data={inboundEmailData} headers={inboundEmailHeaders} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={goNextPage}
            goPrevPage={goPrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default InboundEmailList
