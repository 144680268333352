import { createApi } from '@reduxjs/toolkit/query/react'

import { type AiParseUpdate } from '../../types'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiParseSlice = createApi({
  reducerPath: 'aiParseApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiParses', 'aiParseList'],
  endpoints: (builder) => ({
    getAiParseList: builder.query({
      query: ({
        organizationSid,
        referenceSid,
        humanVerifiedParseResult,
        sortOrder,
        pageToken,
      }: {
        organizationSid?: string
        referenceSid?: string
        humanVerifiedParseResult?: string
        pageToken?: string
        sortOrder?: string
      }) => ({
        url: `/v1/AiParses`,
        params: {
          organizationSid,
          referenceSid,
          sortOrder,
          humanVerifiedParseResult,
          pageToken,
        },
      }),
      providesTags: ['aiParseList'],
    }),
    getAiParse: builder.query({
      query: (sid: string) => `/v1/AiParses/${sid}`,
      providesTags: ['aiParses'],
    }),
    updateAiParse: builder.mutation({
      query: (body: AiParseUpdate) => ({
        url: `/v1/AiParses/${body.sid}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body,
      }),
      invalidatesTags: ['aiParses'],
    }),
    download: builder.query({
      query: (sid: string) => `/v1/AiParses/${sid}/Content`,
    })
  }),
})

export const {
  useLazyGetAiParseListQuery,
  useGetAiParseQuery,
  useUpdateAiParseMutation,
  useLazyDownloadQuery,
} = apiAiParseSlice
