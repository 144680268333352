import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { openSlideOver } from 'store/slideOvers'

import { Button, Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useLazyGetAiPromptListQuery } from '../../../services/aiprompt/AiPromptService'
import { type AiPrompt } from '../../../types'

const AiPromptList = (): JSX.Element => {
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    organizationSid?: string
    eventType?: string
    relation?: string
    sortOrder?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getAiPromptList, { data: aiPromptList, isLoading }] =
    useLazyGetAiPromptListQuery()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const promptLike = searchParams.get('promptLike') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      promptLike,
      sortOrder,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getAiPromptList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'Prompt',
        attribute: 'promptLike',
        value: params.get('promptLike') ?? undefined,
        type: FilterType.TEXT,
      },
    ]
  }, [params])

  if (isLoading || aiPromptList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? undefined

    if (sortOrder === undefined) {
      currentSearchParams.set('sortOrder', 'Asc')
    } else if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const headers = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'CREATED',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? undefined) as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'PROMPT', attribute: 'prompt' },
  ]

  const data = aiPromptList?.aiPromptList?.map((aiPrompt: AiPrompt) => ({
    sid: {
      value: aiPrompt.sid,
      handleClick: (event: KeyboardEvent) => {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/aiprompt/${aiPrompt.sid}`, '_blank', 'noreferer')
        } else {
          navigate(`/aiprompt/${aiPrompt.sid}`)
        }
      },
    },
    created: { value: aiPrompt.created },
    prompt: { value: aiPrompt.prompt.substring(0, 50) },
  }))

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiPromptList?.page?.nextPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiPromptList?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiPromptList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiPromptList?.page?.previousPageToken)
  }

  const disablePrev =
    aiPromptList?.page === undefined ||
    aiPromptList?.page?.previousPageToken === null
  const disableNext =
    aiPromptList?.page === undefined ||
    aiPromptList?.page?.nextPageToken === null

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Prompts</Headline>
            <div>
              <Button
                style="gradient"
                className="ml-4"
                onClick={() => {
                  dispatch(
                    openSlideOver({
                      name: 'CreateAiPrompt',
                      data: ''
                    })
                  )
                }}
              >
                Create AI Prompt
              </Button>
            </div>
          </div>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={data} headers={headers} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default AiPromptList
