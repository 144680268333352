import { useFormik } from 'formik'
import { useState, type FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCreateAiConfigItemMutation } from 'services/aiconfigitems/AiConfigItemService'

import { Button, Slideover, TextArea } from '../components'
import { slideOverSelector } from '../store/selectors'
import { closeSlideOver } from '../store/slideOvers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

export const CreateAiConfigItem: FC = () => {
  const { name, data } = useSelector(slideOverSelector)
  const dispatch = useDispatch()
  const [createAiConfigItem] = useCreateAiConfigItemMutation()
  const [aiConfigSid, setAiConfigSid] = useState(data?.aiConfigSid === undefined ? '' : data.aiConfigSid)
  const [aiConfigItemName, setAiConfigItemName] = useState(data?.name === undefined ? '' : data.name)
  const [aiConfigItemValue, setAiConfigItemValue] = useState(data?.value === undefined ? '' : data.value)
  const [warnAiConfigSid, setWarnAiConfigSid] = useState(aiConfigSid.length === 0)
  const [warnAiConfigItemName, setWarnAiConfigItemName] = useState(aiConfigItemName.length === 0)
  const [warnAiConfigItemValue, setWarnAiConfigItemValue] = useState(aiConfigItemValue.length === 0)
  const [requestError, setRequestError] = useState('')
  const formik = useFormik({
    initialValues: {
      aiConfigSid: aiConfigSid,
      name: aiConfigItemName,
      value: aiConfigItemValue,
    },
    onSubmit: async (values) => {
      try {
        await createAiConfigItem({
          aiConfigSid: aiConfigSid,
          name: aiConfigItemName,
          value: aiConfigItemValue
        }).unwrap()
        dispatch(closeSlideOver())
        setRequestError('')
      } catch (error: any) {
        console.error(error)
        setRequestError(error.data.message)
      }
    },
  })

  const validateAiConfigSid = (inputSid: string | null): void => {
    if (inputSid === null || inputSid.length !== 30 || !/^(AC)[A-Za-z0-9]*$/.test(inputSid)) {
      setWarnAiConfigSid(true)
    } else {
      setWarnAiConfigSid(false)
      setAiConfigSid(inputSid)
    }
    setRequestError('')
  }

  const validateAiConfigItemName = (event: any): void => {
    const name = event.target.value
    if (name === null || name.length === 0) {
      setWarnAiConfigItemName(true)
    } else {
      setWarnAiConfigItemName(false)
      setAiConfigItemName(name)
    }
    setRequestError('')
  }

  const validateAiConfigItemValue = (value: string | null): void => {
    if (value === null || value.length === 0) {
      setWarnAiConfigItemValue(true)
    } else {
      setWarnAiConfigItemValue(false)
      setAiConfigItemValue(value)
    }
    setRequestError('')
  }

  const show = name === 'CreateAiConfigItem'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  return (
    <Slideover open={show} onClose={handleClose} title={`New AI Config Item`}>
      <div>
        <div>
          <div className="text-sm mb-4 block">
            <h3>AI Config Sid:</h3>
          </div>
          <div className="text-offBlack-dark text-sm block pb-5">
            <TextArea
                rows={1}
                defaultValue={aiConfigSid}
                onChange={validateAiConfigSid}
                maxLength={30}
            ></TextArea>
            <div hidden={!warnAiConfigSid} className="pt-1">
              <span className="text-xs font-bold pt-1">A valid AI Config Sid is required</span>
            </div>
          </div>
        </div>

        <div>
          <div className="text-sm mb-4 block">
            <h3>Name:</h3>
          </div>

          <div className="text-offBlack-dark text-sm block pb-5">
              <select
                defaultValue={aiConfigItemName}
                onChange={validateAiConfigItemName}
                className="text-offBlack-dark font-mono text-xs "
              >
                <option value={''}></option>
                <option value={'aiPromptSid'}>aiPromptSid</option>
                <option value={'openAiAssistantId'}>openAiAssistantId</option>
                <option value={'openAiApi'}>openAiApi</option>
                <option value={'model'}>model</option>
              </select>
            <div hidden={!warnAiConfigItemName} className="pt-1">
              <span className="text-xs font-bold pt-1">Name is required</span>
            </div>
          </div>
        </div>

        <div>
          <div className="text-sm mb-4 block">
            <h3>Value:</h3>
          </div>

          <div className="text-offBlack-dark text-sm block">
            <TextArea
                rows={2}
                defaultValue={aiConfigItemValue}
                onChange={validateAiConfigItemValue}
                maxLength={191}
            ></TextArea>
            <div hidden={!warnAiConfigItemValue} className="pt-1">
              <span className="text-xs font-bold pt-1">Value is required</span>
            </div>
          </div>
        </div>

        <div className="justify-end mt-auto absolute bottom-0 right-6">
          <div hidden={requestError.length === 0} className="mb-2 text-sm font-bold pt-1 text-red-500">
            <FontAwesomeIcon icon={light('xmark-circle')} color={'red'} /> Error: {requestError}
          </div>
          <div className="flex justify-end mt-auto right-6">
            <Button style="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button loading={formik.isSubmitting} onClick={formik.handleSubmit} disabled={requestError.length !== 0 || warnAiConfigSid || warnAiConfigItemName || warnAiConfigItemValue}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </Slideover>
  )
}
