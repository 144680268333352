
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Headline, TextArea } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useDeleteAiConfigItemMutation, useGetAiConfigItemQuery, useUpdateAiConfigItemMutation } from "../../../services/aiconfigitems/AiConfigItemService"
import { type MouseEvent, useState } from 'react'
import { type AiConfigItemUpdate } from 'types'
import { openNotification } from 'store/notifications'
import { useAppDispatch } from 'store'
import { AI_CONFIG_ITEMS } from 'routing/paths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getSidPath, isSid } from 'utils/sids/sids'

const AiConfigItem = (): JSX.Element => {
  const { aiConfigItemSid } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [aiConfigItemName, setAiConfigItemName] = useState('null')
  const [aiConfigItemValue, setAiConfigItemValue] = useState('null')
  const [aiConfigItemVersion, setAiConfigItemVersion] = useState(0)
  const [aiConfigItemUpdated, setAiConfigItemUpdated] = useState('null')
  const [shouldSave, setShouldSave] = useState(false)
  const [editValue, setEditValue] = useState(false)
  const [updateAiConfigItem] = useUpdateAiConfigItemMutation()
  const [deleteAiConfigItem] = useDeleteAiConfigItemMutation()

  const { data: aiConfigItem, isLoading } = useGetAiConfigItemQuery(
    aiConfigItemSid as string
  )

  if (isLoading || aiConfigItem === undefined) {
    return <div>Loading...</div>
  }

  if (aiConfigItemName === 'null') {
    setAiConfigItemName(aiConfigItem.name)
  }

  if (aiConfigItemValue === 'null') {
    setAiConfigItemValue(aiConfigItem.value)
  }

  if (aiConfigItemVersion === 0) {
    setAiConfigItemVersion(aiConfigItem.version)
  }

  if (aiConfigItemUpdated === 'null') {
    setAiConfigItemUpdated(aiConfigItem.updated)
  }

  const handleEnablingSave = (name: string | null, value: string | null): void => {
    console.log(aiConfigItem?.name)
    console.log(name)
    console.log(aiConfigItem?.value)
    console.log(value)
    setShouldSave(
      aiConfigItem?.name !== name ||
      aiConfigItem?.value !== value
    )
  }

  const changeAiConfigItemName = (event: any): void => {
    setAiConfigItemName(event.target.value)
    handleEnablingSave(event.target.value, aiConfigItemValue)
  }

  const changeAiConfigItemValue = (value: any): void => {
    setAiConfigItemValue(value)
    handleEnablingSave(aiConfigItemName, value)
  }

  const handleDeleteAiConfigItem = async (): Promise<void> => {
    try {
      await deleteAiConfigItem(aiConfigItemSid as string)
      navigate(AI_CONFIG_ITEMS)
      dispatch(
        openNotification({
          title: 'Deletion Successful',
          message: 'AI Config ' + aiConfigItemSid + ' was deleted',
          type: 'success',
          show: true,
        })
      )
    } catch (e: any) {
      dispatch(
        openNotification({
          title: 'Error',
          message: e?.data?.message ? e.data.message : 'Internal server error',
          type: 'error',
          show: true,
        })
      )
    }
  }

  const handleUpdateAiConfigItem = async (): Promise<void> => {
    try {
      const body: AiConfigItemUpdate = {
        sid: aiConfigItem?.sid,
        currentVersion: aiConfigItemVersion,
        name: aiConfigItem?.state !== aiConfigItemName ? aiConfigItemName : null,
        value: aiConfigItem?.value !== aiConfigItemValue ? aiConfigItemValue : null,
      }
      const updatedAiConfigItem = await updateAiConfigItem(body).unwrap()
      setShouldSave(false)
      setEditValue(false)
      setAiConfigItemVersion(updatedAiConfigItem.version)
      setAiConfigItemUpdated(updatedAiConfigItem.updated)
      dispatch(
        openNotification({
          title: 'Update Successful',
          message: 'AI Config ' + aiConfigItemSid + ' was updated',
          type: 'success',
          show: true,
        })
      )
    } catch (e: any) {
      dispatch(
        openNotification({
          title: 'Error',
          message: e?.data?.message ? e.data.message : 'Internal server error',
          type: 'error',
          show: true,
        })
      )
    }
  }

  const handleEditValue = (event: MouseEvent<SVGSVGElement>): void => {
    setEditValue(true)
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Config Item - {aiConfigItemSid}</Headline>
          </div>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <div>
              <table className="font-semibold w-[100%]">
                <tbody>
                  <tr>
                    <td>
                      <span>Created: {aiConfigItem.created}</span>
                    </td>
                    <td>
                      <span>Last Updated: {aiConfigItemUpdated}</span>
                    </td>
                    <td>
                      <span>Version: {aiConfigItemVersion}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </div>
        <div className="py-1">
          <Card title="AI Config Sid">
            <span><a href={"/aiconfigs/" + aiConfigItem.sid}>{aiConfigItem.sid}</a></span>
          </Card>
        </div>
        <div className="py-1">
          <Card title="Name">
              <select
                defaultValue={aiConfigItemName}
                onChange={changeAiConfigItemName}
                className="text-offBlack-dark font-mono text-xs "
              >
                <option value={''}></option>
                <option value={'aiPromptSid'}>aiPromptSid</option>
                <option value={'openAiAssistantId'}>openAiAssistantId</option>
                <option value={'openAiApi'}>openAiApi</option>
                <option value={'model'}>model</option>
              </select>
          </Card>
        </div>
        <div className="py-1">
          <Card title="Value">
              <span hidden={editValue}>
                <a href={getSidPath(aiConfigItemValue)} hidden={!isSid(aiConfigItemValue)} className='text-blue-700 underline cursor-pointer'>{aiConfigItemValue}</a>
                <span hidden={isSid(aiConfigItemValue)}>{aiConfigItemValue}</span>
                &nbsp;<FontAwesomeIcon icon={light('pencil')} color={'black'} onClick={handleEditValue} cursor={'pointer'}/>
              </span>
              <span hidden={!editValue}>
                <TextArea
                    rows={2}
                    defaultValue={aiConfigItemValue}
                    onChange={changeAiConfigItemValue}
                    maxLength={191}
                ></TextArea>
              </span>
          </Card>
        </div>
        <div className="w-[100%] flex flex-row-reverse">
          <div className="self-end pt-4 px-3">
            <div>
              <Button
                disabled={!shouldSave}
                onClick={() => {
                  handleUpdateAiConfigItem()
                }}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="self-end pt-4">
            <div>
              <Button
                className='bg-red-500'
                onClick={() => {
                  handleDeleteAiConfigItem()
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AiConfigItem
