
import { useParams } from 'react-router-dom'

import {Button, Headline} from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {useGetAiPromptQuery} from "../../../services/aiprompt/AiPromptService";
import {openSlideOver} from "../../../store/slideOvers";
import {useDispatch} from "react-redux";

const AiPrompt = (): JSX.Element => {
  const { aiPromptSid } = useParams()
  const dispatch = useDispatch()

  const { data: aiPrompt, isLoading } = useGetAiPromptQuery(
    aiPromptSid as string
  )

  if (isLoading || aiPrompt === undefined) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div>
        <TopSection sm>
          <div className="flex justify-between">
            <Headline className="text-offWhite-light">AI Prompts - {aiPromptSid}</Headline>
            <div>
              <Button
                  style="gradient"
                  className="ml-4"
                  onClick={() => {
                    dispatch(
                        openSlideOver({
                          name: 'CreateAiPrompt',
                          data: aiPrompt.prompt
                        })
                    )
                  }}
              >
                Edit AI Prompt
              </Button>
            </div>
          </div>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <div>
              <table className="font-semibold w-[100%]">
                <tbody>
                  <tr>
                    <td>
                      <span>Created: {aiPrompt.created}</span>
                    </td>
                    <td>
                      <span>Last Updated: {aiPrompt.updated}</span>
                    </td>
                    <td>
                      <span>Version: {aiPrompt.version}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="py-4">
              <Card>
                <div className="whitespace-pre-line text-justify max-h-[350px] overflow-scroll">
                  {aiPrompt.prompt}
                </div>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
export default AiPrompt
