import { type ReactNode } from 'react'

interface TableRowProps {
  children: ReactNode
  handleClick?: () => void
  link?: boolean
  overFlow?: boolean
}

export const TableRow = ({
  children,
  link = false,
  handleClick = () => {},
  overFlow = false,
}: TableRowProps): JSX.Element => {
  return (
    <td
      className={`max-w-[225px] whitespace-nowrap py-4 pl-5 sm:pl-8 pr-3 text-xs text-center font-medium item-center ${
        link ? 'cursor-pointer underline text-blue-700' : ''
      }
      ${ overFlow ? 'overflow-hidden hover:overflow-scroll hover:text-clip' : ''}
      `}
      onClick={handleClick}
    >
      {children}
    </td>
  )
}
