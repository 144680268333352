import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiDownloadSlice = createApi({
  reducerPath: 'downloads',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['downloads'],
  endpoints: (builder) => ({
    getConfigDownloadUrl: builder.query({
        query: (sid: string) => `/v1/AiParseInputs/${sid}/Content`,
        providesTags: ['downloads'],
    }),
  }),
})

export const { useLazyGetConfigDownloadUrlQuery } =
  apiDownloadSlice