import { type FC, type ReactNode } from 'react'

interface TopSectionProps {
  children: ReactNode
  sm?: boolean
}

export const TopSection: FC<TopSectionProps> = ({
  children,
  sm = false,
}: TopSectionProps) => {
  return (
    <div
      style={{ height: sm ? '185px' : '260px' }}
      className={'bg-offBlack-dark pt-8 px-5 sm:px-8'}
    >
      {children}
    </div>
  )
}
