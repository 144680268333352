import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from "../publicApiBaseQuery";

export const apiEventSlice = createApi({
  reducerPath: 'eventApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['event'],
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: (sid: string) => `/v1/Events/${sid}`,
      providesTags: ['event'],
    }),
  }),
})

export const { useGetEventQuery } = apiEventSlice
