import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import ReactJson from 'react-json-view'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Headline } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useGetInboundEmailQuery } from '../../../services/inboundemails/InboundEmailService'
import { contentToJson } from '../../../utils/json/jsonParser'
import { type MouseEvent } from 'react'

const InboundEmail = (): JSX.Element => {
  const { inboundEmailSid } = useParams()
  const navigate = useNavigate()

  const handleOpen = (event: MouseEvent): void => {
    if (event.metaKey) {
      window.open(
        '/aiparses?referenceSid=' + inboundEmailSid,
        '_blank',
        'noreferer'
      )
    } else {
      navigate('/aiparses?referenceSid=' + inboundEmailSid)
    }
  }

  const { data: inboundEmail, isLoading } = useGetInboundEmailQuery(
    inboundEmailSid as string
  )

  if (isLoading || inboundEmail === undefined) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Inbound Email - {inboundEmailSid}
          </Headline>
          { inboundEmail.frontUrl !== null && (<Button
            className="mt-1"
            onClick={() => {
              window.open(`https://app.frontapp.com/open/${inboundEmail.frontUrl}`)
            }}>
            Open in Front <FontAwesomeIcon icon={light('arrow-up-right-from-square')} />
          </Button>)}
        </TopSection>
      </div>
      <div className="p-3">
        <Card>
          <div className="font-semibold text-offWhite-dark font-mono">
            <table className="w-[100%] fa-table-layout">
              <tbody>
                <tr key={inboundEmail.fromEmail}>
                  <td>
                    <span className="text-offBlack-dark text-sm">
                      From: {inboundEmail.fromEmail}
                    </span>
                  </td>
                  <td className="justify-end flex items-center gap-[5px]">
                    <div>
                      {/* TODO use when the s3 service is available to download the content of the email
                                        <button
                                            onClick={() => {
                                                alert("Download from s3")
                                            }}
                                            className={clsx(
                                                'rounded-full h-7 w-7 p-[5px] flex justify-center',
                                                {
                                                    'bg-offBlack-dark shadow-base': true
                                                }
                                            )}
                                        >
                                            <FontAwesomeIcon icon={light('download')}/>
                                        </button> */}
                      <button
                        onClick={handleOpen}
                        className={clsx(
                          'rounded-full h-7 w-7 p-[5px] flex justify-center',
                          {
                            'bg-offBlack-dark shadow-base': true,
                          }
                        )}
                      >
                        <FontAwesomeIcon icon={light('brain')} />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="py-2 text-offBlack-dark">
              <span className="text-offBlack-dark text-sm">
                To: {inboundEmail.toEmails?.join(',')}
              </span>
            </div>
            <div className="py-2">
              <span className="text-offBlack-dark text-sm">
                CC: {inboundEmail.ccEmails?.join(',')}
              </span>
            </div>
            <div className="py-2">
              <span className="text-offBlack-dark text-sm">
                Date: {inboundEmail.created}
              </span>
            </div>
            <hr className="fa-border-top border-dashed border-offBlack-tooltip" />
            <div className="pt-3">
              <div>
                {inboundEmail?.content !== null ? (
                  <span className="text-offBlack-dark text-sm">
                    {inboundEmail.content}
                  </span>
                ) : (
                  <div className="py-5">
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
        {
          // TODO delete when a service to download from the s3 is available
        }
        <div className="py-1">
          <Card title={'S3 Data'}>
            <div className="font-semibold text-offBlack-dark text-xs font-mono">
              <div>
                <span>Bucket: {inboundEmail.s3Bucket}</span>
              </div>
              <div>
                <span>Key: {inboundEmail.s3Key}</span>
              </div>
            </div>
          </Card>
        </div>
        <Card title="JSON Data">
          <div>
            <div className="overflow-x-auto w-[1250px] text-offWhite-dark font-mono text-xs">
              <div className="whitespace-pre-line ">
                <ReactJson
                  collapseStringsAfterLength={150}
                  src={
                    inboundEmail?.emailJson !== null
                      ? contentToJson(inboundEmail.emailJson)
                      : JSON.parse('{}')
                  }
                  name={null}
                  displayDataTypes={false}
                  theme="rjv-default"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default InboundEmail
