import { type FC, type ReactNode } from 'react'

import { type headLineSizes } from '../../types'

export interface HeadlineProps {
  size?: headLineSizes
  children: ReactNode
  className?: string
}

export const Headline: FC<HeadlineProps> = ({
  children,
  size = 'md',
  className = '',
}) => {
  const getHeadLineSize = (size: headLineSizes): string => {
    return {
      xxl: 'text-6xl',
      xl: 'text-2xl sm:text-4xl',
      md: 'text-2xl',
      sm: 'text-xl',
    }[size]
  }
  return <h1 className={`${getHeadLineSize(size)} ${className}`}>{children}</h1>
}
