import { type Slice, createSlice } from '@reduxjs/toolkit'

import {
  closeNotificationAction,
  openNotificationAction,
} from './actions/notifications'

export interface NotificationState {
  title: string
  message: string
  type?: 'success' | 'error' | 'warning'
  show?: boolean
}

const initialState: NotificationState = {
  title: '',
  message: '',
  show: false,
}

const notificationsSlice: Slice<NotificationState> = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openNotification: openNotificationAction,
    closeNotification: closeNotificationAction,
  },
})

export const { openNotification, closeNotification } =
  notificationsSlice.actions
export default notificationsSlice.reducer
