import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { apiEnv } from './apiUrls'

const baseUrl =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? 'http://localhost'
    : apiEnv[
        process.env.REACT_APP_ENVIRONMENT as
          | 'production'
          | 'staging'
          | 'local'
          | 'gov'
      ]

const publicAPIBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken: string | null = localStorage.getItem('token')
    if (accessToken != null) {
      headers.set(
        'Authorization',
        `AKh7qhk7m9kfbd377m3y8jqg4ft4qh:${accessToken}`
      )
    }
    return headers
  },
})

export default publicAPIBaseQuery
