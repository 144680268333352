import clsx from 'clsx'
import {
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactNode,
  useState,
} from 'react'

export interface FormFieldProps {
  icon?: ReactNode
  className?: string
  label?: string | null
  inputName: string
  onChange?: (value: any) => void
  disabled?: boolean
  error?: string | null
  defaultValue?: any
  type?: string
  placeholder?: string
  success?: string | null
  onClick?: MouseEventHandler<HTMLInputElement>
  readOnly?: boolean
  onSubmit?: (() => void) | null
  setTouched?: (inputName: string, touched: boolean, validate: boolean) => void
  touched?: boolean
  hint?: string | null
  cySelector?: string
}

export const FormField = ({
  className = '',
  icon,
  onChange = () => {},
  disabled = false,
  label = null,
  inputName,
  error = null,
  success = null,
  defaultValue = undefined,
  type = 'text',
  placeholder = ' ',
  onClick,
  setTouched = () => {},
  touched = true, // TODO: change to false when this field is on all other forms
  readOnly = false,
  hint = null,
  onSubmit,
  cySelector = '',
}: FormFieldProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false)
  const [inputType, setInputType] = useState(type)
  const inputMessage = error ?? success ?? hint ?? null

  const getInputMessageColor = (): { text: string; border: string } => {
    let text, border

    if (error !== null) {
      if (touched) {
        text = 'text-red-dark'
        border = 'focus-within:border-red-500 border-red-500'
      } else {
        text = 'text-offBlack'
        border = 'border-offBlack'
      }
    } else if (hint !== null && success === null) {
      text = 'text-offBlack-light'
      border = 'border-offBlack'
    } else {
      text = 'text-green-dark'
      border = 'focus-within:border-teal-mid border-transparent'
    }

    return { text, border }
  }

  const handleToggleShowPassword = (): void => {
    if (!showPassword) {
      setInputType('text')
    } else {
      setInputType('password')
    }
    setShowPassword((prevState) => !prevState)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit?.()
    }
  }

  const handleChange = (e: any): void => {
    setTouched(inputName, true, false)
    onChange(e)
  }

  const colors = getInputMessageColor()
  return (
    <>
      <div
        className={clsx(
          `input relative rounded-md border transition shadow-md ${colors.border}`,
          className
        )}
      >
        <input
          id={`floating_outlined_${inputName}`}
          name={inputName}
          onChange={(e) => {
            handleChange(e)
          }}
          value={defaultValue}
          type={inputType}
          data-cy={cySelector}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          className={clsx(
            'block w-full text-sm text-offBlack-dark rounded-lg border-0 appearance-none focus:outline-none focus:ring-0 peer',
            { 'px-2.5 pb-2.5 pt-4': !(label == null) },
            { 'px-2.5 py-2.5': label === null },
            { 'bg-offBlack-lightest': disabled },
            { 'bg-white': !disabled }
          )}
          placeholder={placeholder}
          onClick={onClick}
          readOnly={readOnly}
        />
        {!(label == null) && (
          <label
            htmlFor={`floating_outlined_${inputName}`}
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-offBlack-light peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-4 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-placeholder-shown:text-offBlack-light"
          >
            {label}
          </label>
        )}
        {icon !== null && icon !== undefined ? (
          <button
            className="z-10 absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={handleToggleShowPassword}
          >
            <div className="h-5 w-5 text-gray-400" aria-hidden="true">
              {icon}
            </div>
          </button>
        ) : null}
      </div>
      {inputMessage !== null ? (
        <>
          {touched && (
            <div className={'min-h-max'}>
              <span className={`text-sm flex ${colors.text}`}>
                {inputMessage}
              </span>
            </div>
          )}
        </>
      ) : null}
    </>
  )
}
