import React, { type MouseEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Headline } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {
  useGetAiParseReviewQuery,
  useUpdateAiParseReviewMutation,
} from '../../../services/aiparsereviews/AiParseReviewService'
import { useAppDispatch } from '../../../store'
import { openNotification } from '../../../store/notifications'
import { type AiParseReviewUpdate } from '../../../types'

const AiParseReview = (): JSX.Element => {
  const { aiParseReviewSid } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [aiState, setAiState] = useState('null')
  const [aiReviewedInit, setAiReviewed] = useState(null)
  const [isReviewed, setIsReviewed] = useState(false)
  const [shouldSave, setShouldSave] = useState(false)

  const [updateAiParseReview] = useUpdateAiParseReviewMutation()
  const { data: aiParseReview, isLoading } = useGetAiParseReviewQuery(
    aiParseReviewSid as string
  )

  if (isLoading || aiParseReview === undefined) {
    return <div>Loading...</div>
  }

  if (aiState === 'null') {
    setAiState(aiParseReview.state)
  }

  if (aiReviewedInit === null) {
    setAiReviewed(
      aiParseReview?.reviewed === null ? 'null' : aiParseReview?.reviewed
    )
    setIsReviewed(aiParseReview?.reviewed !== null)
  }

  function changeAiState(event: any): void {
    setAiState(event.target.value)
    handleEnablingSave(event.target.value, isReviewed)
  }

  function changeReviewed(): void {
    setIsReviewed(!isReviewed)
    handleEnablingSave(aiState, !isReviewed)
  }

  const handleEnablingSave = (state: string | null, review: boolean): void => {
    setShouldSave(
      aiParseReview?.state !== state ||
        (aiParseReview?.reviewed === null && review) ||
        (aiParseReview?.reviewed !== null && !review)
    )
  }

  const handleUpdateAiParseReview = (): void => {
    const aiReviewed =
      aiParseReview?.reviewed === null && isReviewed
        ? true
        : aiParseReview?.reviewed !== null && !isReviewed
        ? false
        : null
    try {
      const body: AiParseReviewUpdate = {
        sid: aiParseReview?.sid,
        state: aiParseReview?.state !== aiState ? aiState : null,
        reviewed: aiReviewed,
      }
      updateAiParseReview(body)
      setShouldSave(false)
      dispatch(
        openNotification({
          title: 'Update Successful',
          message: 'AI Parse Review ' + aiParseReviewSid + ' was updated',
          type: 'success',
          show: true,
        })
      )
    } catch (e) {
      dispatch(
        openNotification({
          title: 'Error',
          message: 'Internal Server Error',
          type: 'error',
          show: true,
        })
      )
    }
  }

  const handleOpen = (event: MouseEvent): void => {
    const relationPath = `/eventrelations?relation=factor:createdByAiParse:${aiParseReviewSid}`
    if (event.metaKey) {
      window.open(relationPath, '_blank', 'noreferer')
    } else {
      navigate(relationPath)
    }
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Ai Parses Review - {aiParseReviewSid}
          </Headline>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
        <div className="py-1">
          <Card title="Details">
            <div>
              <table className="font-semibold w-[100%]">
                <tbody>
                  <tr>
                    <td>
                      <span>Created: {aiParseReview.created}</span>
                    </td>
                    <td>
                      <span>Last Updated: {aiParseReview.updated}</span>
                    </td>
                    <td>
                      <span>Version: {aiParseReview.version}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3">
                      <div className="inline-flex">
                        <p>AI Parse Sid: </p>{' '}
                        <div
                          className="cursor-pointer underline text-blue-700 pl-2"
                          onClick={() => {
                            navigate(`/aiparses/${aiParseReview.aiParseSid}`)
                          }}
                        >
                          {aiParseReview.aiParseSid}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="inline-flex">
                        <p>Reference Sid:</p>{' '}
                        <div
                          className="cursor-pointer underline text-blue-700 pl-2"
                          onClick={() => {
                            navigate(
                              `/inboundemails/${aiParseReview.referenceSid}`
                            )
                          }}
                        >
                          {aiParseReview.referenceSid}
                        </div>
                      </div>
                    </td>
                    <td>
                      {aiParseReview.organizationSid !== null && (
                        <span>
                          Organization Sid: {aiParseReview.organizationSid}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3 inline-flex">
                      <div>
                        <div>
                          <label
                            className="text-offBlack-dark font-mono text-sm pr-3"
                            htmlFor="reviewed"
                          >
                            Reviewed?
                          </label>
                          <input
                            type="checkbox"
                            id="reviewedCheckbox"
                            defaultChecked={aiParseReview?.reviewed !== null}
                            onChange={changeReviewed}
                          />
                        </div>
                      </div>
                    </td>
                    <td hidden={aiParseReview?.reviewed === null}>
                      <span>Reviewed on: {aiParseReview.reviewed}</span>
                    </td>
                    <td hidden={aiParseReview?.reviewedBy === null}>
                      <span>Reviewed by: {aiParseReview.reviewedBy}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3">
                      <div className="inline-flex items-center">
                        <span>State: </span>
                        <div className="p-1">
                          <select
                            defaultValue={aiState}
                            onChange={changeAiState}
                            className="text-offBlack-dark font-mono text-xs "
                          >
                            <option value={'created'}>Created</option>
                            <option value={'parsing'}>Parsing</option>
                            <option value={'processing'}>Processing</option>
                            <option value={'applied'}>Applied</option>
                            <option value={'failed_processing'}>
                              Failed Processing
                            </option>
                            <option value={'invalid'}>Invalid</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>Type: {aiParseReview.type}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-[100%] flex flex-row-reverse">
              <div className="self-end pt-4 px-3">
                <div>
                  <Button
                    disabled={!shouldSave}
                    onClick={() => {
                      handleUpdateAiParseReview()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div className="self-end pt-4 px-3">
                <div>
                  <div onClick={handleOpen}>
                    <Button>Events</Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
export default AiParseReview
