import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useRef } from 'react'

export interface TableHeaderPops {
  children: string
  sortDirection?: 'Asc' | 'Desc'
  link?: boolean
  handleSort?: () => void
  overFlow?: boolean
  tooltips?: string[]
}

export const TableHeader = ({
  children,
  sortDirection,
  link = false,
  handleSort = () => {},
  tooltips,
}: TableHeaderPops): JSX.Element => {
  const headerRef = useRef(null)
  return (
    <th
      className="cursor-pointer py-3.5 font-semibold text-offBlack-mid text-xs font-mono pb-3 text-center"
      onClick={handleSort}
      ref={headerRef}
    >
      {children}
      {sortDirection !== undefined && (
        <span className="pl-1">
          {sortDirection === 'Asc' && (
            <FontAwesomeIcon icon={light('arrow-up')} />
          )}
          {sortDirection === 'Desc' && (
            <FontAwesomeIcon icon={light('arrow-down')} />
          )}
        </span>
      )}
      {tooltips !== undefined && (
        <span>
          &nbsp;
          <FontAwesomeIcon icon={light('square-question')} />
          <Tooltip position="top" ref={headerRef}>
            <p>
                {tooltips.map((tooltip) => (
                  <div key={tooltip}>{tooltip}</div>
                ))}
            </p>
          </Tooltip>
        </span>
      )}
    </th>
  )
}
