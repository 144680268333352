import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiAiParseInputSlice = createApi({
  reducerPath: 'aiParseInputs',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['aiParseInput', 'aiParseInputList'],
  endpoints: (builder) => ({
    getAiParseInput: builder.query({
        query: (sid: string) => `/v1/AiParseInputs/${sid}`,
        providesTags: ['aiParseInput'],
    }),
    getAiParseInputList: builder.query({
      query: ({
        organizationSid,
        aiParseSid,
        pageToken,
      }: {
        organizationSid?: string
        aiParseSid?: string
        pageToken?: string
      }) => ({
        url: `/v1/AiParseInputs`,
        params: {
          organizationSid,
          aiParseSid,
          pageToken,
          pageSize: 12,
        },
      }),
      providesTags: ['aiParseInputList'],
    }),
  }),
})

export const { useGetAiParseInputQuery, useLazyGetAiParseInputListQuery } =
  apiAiParseInputSlice