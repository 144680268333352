import {
  type AnyAction,
  type CaseReducer,
  type Draft,
  type PayloadAction,
} from '@reduxjs/toolkit'

import { type NotificationState } from '../notifications'

export const openNotificationAction: CaseReducer<
  NotificationState,
  PayloadAction<NotificationState>
> = (state: Draft<NotificationState>, action: AnyAction) => {
  state.title = action.payload.title
  state.message = action.payload.message
  state.show = action.payload.show
  state.type = action.payload.type
}

export const closeNotificationAction: CaseReducer<NotificationState> = (
  state
) => {
  state.title = ''
  state.message = ''
  state.show = false
}
