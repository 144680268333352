import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiInboundEmailSlice = createApi({
  reducerPath: 'inboundEmailApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['inboundEmails', 'inboundEmailList'],
  endpoints: (builder) => ({
    getInboundEmailList: builder.query({
      query: ({
        fromEmail,
        toEmails,
        ccEmails,
        sortOrder,
        pageToken,
      }: {
        fromEmail?: string
        toEmails?: string
        ccEmails?: string
        sortOrder?: string
        pageToken?: string
      }) => {
        const params = {
          fromEmail,
          toEmails,
          ccEmails,
          sortOrder,
          pageToken,
        }

        const filteredParams = Object.entries(params).reduce(
          (acc: any, [key, value]) => {
            if (value) {
              acc[key] = value
            }
            return acc
          },
          {}
        )

        return {
          url: `/v2/InboundEmails`,
          params: filteredParams,
        }
      },
      providesTags: ['inboundEmailList'],
    }),
    getInboundEmail: builder.query({
      query: (sid: string) => `/v1/InboundEmails/${sid}`,
      providesTags: ['inboundEmails'],
    }),
  }),
})

export const {
  useGetInboundEmailListQuery,
  useLazyGetInboundEmailListQuery,
  useGetInboundEmailQuery,
} = apiInboundEmailSlice
