import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Headline } from '../../components'
import { Pagination } from '../../components/Pagination/Pagination'
import { Table } from '../../components/Table/Table'
import { TopSection } from '../../components/TopSection/TopSection'
import { useGetOrganizationsQuery } from '../../services/organizations/Organizations'

interface Organization {
  sid: string
  name: string
  created: string
  updated: string
  version: number
  status: string
}

const Organizations = (): JSX.Element => {
  const [params] = useSearchParams()
  const location = useLocation()
  const [, setPageToken] = useState(undefined)
  const { data, isLoading } = useGetOrganizationsQuery({
    pageToken: params.get('pageToken') ?? undefined,
  })
  const navigate = useNavigate()

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', data.page.nextPageToken)

    navigate(`/organizations?${searchParams.toString()}`, { replace: true })
    setPageToken(data.page.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', data.page.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(data.page.previousPageToken)
  }

  if (isLoading || data === undefined) {
    return <div>Loading...</div>
  }
  const { organizations } = data

  const orgHeaders = [
    { label: 'NAME', attribute: 'name' },
    { label: 'STATUS', attribute: 'status' },
    { label: 'ACTIONS', attribute: 'actions' },
  ]

  const orgData = organizations.map((org: Organization) => ({
    name: { value: org.name },
    status: { value: org.status },
    actions: {
      value: <Button style="primary">Login</Button>,
    },
  }))

  const disablePrev =
    data.page === undefined || data.page?.previousPageToken === null
  const disableNext =
    data.page === undefined || data.page?.nextPageToken === null

  return (
    <div>
      <TopSection sm>
        <div className="flex justify-between">
          <Headline className="text-offWhite-light">Organizations</Headline>
          <div>
            <Button style="gradient" className="ml-4">
              Create Organization
            </Button>
          </div>
        </div>
      </TopSection>
      <div className="flex w-[100%] font-mono p-20">
        <div className="w-[100%]">
          <Table data={orgData} headers={orgHeaders} />
        </div>
      </div>
      <div className="pr-10">
        <Pagination
          disablePrev={disablePrev}
          disableNext={disableNext}
          goNextPage={handleNextPage}
          goPrevPage={handlePrevPage}
        />
      </div>
    </div>
  )
}

export default Organizations
