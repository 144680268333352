import { type MouseEvent } from 'react'
import ReactJson from 'react-json-view'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button, Headline } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {
  useDeleteEventRelationMutation,
  useGetEventRelationsQuery,
} from '../../../services/eventrelations/EventRelationService'
import { useGetEventQuery } from '../../../services/events/EventService'
import { contentToJson, isValidJson } from '../../../utils/json/jsonParser'
import { getReferenceFromRelation } from '../../../utils/sids/sids'

const EventRelation = (): JSX.Element => {
  const { eventRelationSid } = useParams()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { data: eventRelation, isLoading } = useGetEventRelationsQuery(
    eventRelationSid as string
  )
  const eventSid = params.get('eventSid')
  const { data: event, isLoading: isLoadingEvent } = useGetEventQuery(
    eventSid as string
  )
  const [deleteEventRelation] = useDeleteEventRelationMutation({})

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (eventRelation?.eventSid !== undefined && eventSid === null) {
    console.log('missing eventSid parameter... redirecting...')
    navigate(`?eventSid=${eventRelation.eventSid}`)
  }

  const deleteRelation = (): void => {
    deleteEventRelation(eventRelationSid as string).then(() => {
      navigate(`/eventrelations`)
    })
  }

  const relationPath = getReferenceFromRelation(eventRelation.relation)

  const handleOpen = (event: MouseEvent): void => {
    if (relationPath === 'Unknown') return
    if (event.metaKey) {
      window.open(relationPath, '_blank', 'noreferer')
    } else {
      navigate(relationPath)
    }
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Event Relations - {eventRelationSid}
          </Headline>
        </TopSection>
      </div>
      <div className="text-offBlack-ligth font- text-sm p-5">
        <div className="py-1">
          <Card title="Event Relation">
            <table className="w-[100%] pt-3">
              <tbody>
                <tr>
                  <td>
                    <span>Created: {eventRelation.created}</span>
                  </td>
                  <td>
                    <span>Last Updated: {eventRelation.updated}</span>
                  </td>
                  <td>
                    <span>Version: {eventRelation.version}</span>
                  </td>
                  <td>
                    {eventRelation?.organizationSid !== undefined ?? (
                      <span>
                        Organization SID: {eventRelation.organizationSid}
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-3">
                    <span>Event SID: {eventRelation.eventSid}</span>
                  </td>
                  <td>
                    <span>Event Type: {eventRelation.eventType} </span>
                  </td>
                  <td>
                    <span className="inline-flex">
                      <div className="pr-1">Relation:</div>
                      <div
                        className={`${
                          relationPath === 'Unknown'
                            ? ''
                            : 'text-blue-700 underline cursor-pointer'
                        } `}
                        onClick={handleOpen}
                      >
                        {eventRelation.relation}
                      </div>
                    </span>
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
            <div className="w-[100%] flex flex-row-reverse">
              <div className="self-end px-3">
                <Button onClick={deleteRelation}>Delete</Button>
              </div>
            </div>
          </Card>
          <div className="py-2" />
          {isLoadingEvent || event === null || event === undefined ? (
            <div />
          ) : (
            <Card title="Event Details">
              <table className="w-[100%] pt-3">
                <tbody>
                  <tr>
                    <td>
                      <span>SID: {event?.sid}</span>
                    </td>
                    <td>
                      <span>Time: {event?.eventTime}</span>
                    </td>
                    <td>
                      <span>Type: {event?.eventType}</span>
                    </td>
                    <td>
                      {(event?.organizationSid !== undefined || true) ?? (
                        <span>Organization SID: {event.organizationSid}</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-3">
                      <span>Details:</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="whitespace-pre-line py-3">
                {isValidJson(event?.event) ? (
                  <ReactJson
                    collapseStringsAfterLength={150}
                    src={contentToJson(event?.event)}
                    name={null}
                    displayDataTypes={false}
                    theme="rjv-default"
                  />
                ) : (
                  <div>{event?.event}</div>
                )}
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  )
}

export default EventRelation
