import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from 'assets/phoenix.png'
import React, { type FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { NavItem } from 'components/NavItem/NavItem'

import { type INavItem } from 'types'

import { useDeleteLogoutMutation } from '../../services/auth/Auth'
import { useAppDispatch } from '../../store'
import { setLoggedOut } from '../../store/session'

export interface SidebarProps {
  navItems: INavItem[]
}

export const Sidebar: FC<SidebarProps> = ({ navItems }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [logoutMutation] = useDeleteLogoutMutation({})

  const logout = (): void => {
    logoutMutation({}).then(() => {
      dispatch(setLoggedOut())
      navigate('/login')
    })
  }

  return (
    <div className="fixed flex flex-col bottom-0 w-full sm:w-[114px] xl:w-[200px] sm:h-screen bg-brand-gradient sm:sticky sm:top-0 transition-all text-xs">
      <div>
        <img src={Logo} alt="Factor Logo" />
      </div>
      <div className="flex flex-row sm:flex-col justify-between h-[calc(100%-9rem)] sm:px-6 overflow-hidden overflow-scroll">
        <div className="flex flex-row sm:flex-col w-2/3 sm:w-full">
          {navItems.map((item) => (
            <div key={item.url} className="sm:mb-4 w-1/2 sm:w-full">
              <NavItem
                url={item.url}
                icon={item.icon}
                active={location.pathname.includes(item.url)}
                id={`${item.label}-navitem`}
              >
                {item.label}
              </NavItem>
            </div>
          ))}
        </div>
        <div className="w-1/3 sm:w-full">
          <div
            onClick={logout}
            className={`flex flex-col items-center xl:flex-row xl:pl-4 xl:pr-8 py-3 sm:py-2 text-xs xl:text-sm inline-block rounded-3xl font-medium cursor-pointer`}
          >
            <div className="mr-4 mb-2">
              <FontAwesomeIcon icon={light('arrow-right-from-bracket')} />
            </div>
            Log out
          </div>
        </div>
      </div>
    </div>
  )
}
