import { type RootState } from './index'
import { type NotificationState } from './notifications'
import { type SessionState } from './session'
import { type SlideOversState } from './slideOvers'

export const slideOverSelector = (state: RootState): SlideOversState =>
  state.slideOvers
export const sessionSelector = (state: RootState): SessionState => state.session

export const notificationSelector = (state: RootState): NotificationState =>
  state.notifications

export const featuresSelector = (state: RootState): string[] =>
  state.session.features ?? []
