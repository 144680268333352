import { useParams } from 'react-router-dom'

import { Headline } from '../../../components'
import { Card } from '../../../components/Card/Card'
import { TopSection } from '../../../components/TopSection/TopSection'
import {
    useGetAiParseInputQuery
} from '../../../services/aiparseinputs/AiParseInputService'
import {
  useLazyGetConfigDownloadUrlQuery
} from '../../../services/downloads/DownloadService'

const AiParseInput = (): JSX.Element => {
  const { aiParseInputSid } = useParams()

  const { data: aiParseInput, isLoading } = useGetAiParseInputQuery(
    aiParseInputSid as string
  )

  const [ getAiParseInputDownloadUrl ] = useLazyGetConfigDownloadUrlQuery()

  if (isLoading || aiParseInput === undefined) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            Ai Parse Input - {aiParseInput.sid}
          </Headline>
        </TopSection>
      </div>
      <div className="text-offBlack-dark font-mono text-sm p-5">
      <div className="py-1">
        <Card title="Details">
          <table className="font-semibold w-[100%]">
            <tbody>
              <tr>
                <td>
                  <span>Created: {aiParseInput.created}</span>
                </td>
                <td>
                  {aiParseInput.organizationSid && (
                    <span>
                      Organization Sid: {aiParseInput.organizationSid}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="inline-flex">
                    <p>AI Parse Sid: </p>
                    <a className="underline text-blue-700 pl-2" href={`/aiparses/${aiParseInput.aiParseSid}`}>
                      {aiParseInput.aiParseSid}
                    </a>
                  </div>
                </td>
                <td>
                  <span>Input Source: {aiParseInput.inputSource}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
      {aiParseInput.inputSource === 'textData' && (
        <div className="py-1">
          <Card title="Text Data">
            <div className="font-semibold text-xs text-justify">
              {aiParseInput.textData}
            </div>
          </Card>
        </div>
      )}
      {aiParseInput.inputSource === 's3' && (
                <Card title="S3 File">
                    <div className="font-semibold text-xs text-justify">
                        <div className="cursor-pointer underline text-blue-700 pl-2">
                        <a
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            const { url: aiParseInputDownloadUrl } = await getAiParseInputDownloadUrl(aiParseInput.sid).unwrap();
                            const downloadLink = document.createElement('a');
                            downloadLink.href = aiParseInputDownloadUrl;
                            downloadLink.setAttribute('download', '');
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                          }}
                        >
                          {`s3://${aiParseInput.s3Bucket}/${aiParseInput.s3Key}`}
                        </a>
                        </div>
                    </div>
                </Card>
              )}
    </div>
    </>
  )
}

export default AiParseInput
