import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Headline } from '../../../components'
import { Pagination } from '../../../components/Pagination/Pagination'
import { Table } from '../../../components/Table/Table'
import {
  FilterType,
  TableFilters,
} from '../../../components/TableFilters/TableFilters'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useLazyGetAiParseListQuery } from '../../../services/aiparses/AiParseService'
import { type AiParse } from '../../../types'
import { getSidPath } from '../../../utils/sids/sids'

const AiParseList = (): JSX.Element => {
  const [params] = useSearchParams()
  const [searchParams, setSearchParams] = useState<{
    organizationSid?: string
    referenceSid?: string
    humanVerifiedParseResult?: string
    sortOrder?: string
    pageToken?: string
  }>({})
  const location = useLocation()
  const navigate = useNavigate()
  const [, setPageToken] = useState(undefined)
  const [getAiParseList, { data: aiParseList, isLoading }] =
    useLazyGetAiParseListQuery()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const organizationSid = searchParams.get('organizationSid') ?? undefined
    const referenceSid = searchParams.get('referenceSid') ?? undefined
    const humanVerifiedParseResult =
      searchParams.get('humanVerifiedParseResult') ?? undefined
    const sortOrder = searchParams.get('sortOrder') ?? undefined
    const pageToken = searchParams.get('pageToken') ?? undefined

    const currentSearchParams = {
      organizationSid,
      referenceSid,
      humanVerifiedParseResult,
      sortOrder,
      pageToken,
    }

    const currentParamsFiltered = Object.fromEntries(
      Object.entries(currentSearchParams).filter(
        ([_, value]) => value !== undefined
      )
    )

    setSearchParams(currentParamsFiltered)
    getAiParseList(currentSearchParams)
  }, [params])

  const tableFilters = useMemo(() => {
    return [
      {
        label: 'Organization SID',
        attribute: 'organizationSid',
        value: params.get('organizationSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'Reference SID',
        attribute: 'referenceSid',
        value: params.get('referenceSid') ?? undefined,
        type: FilterType.TEXT,
      },
      {
        label: 'Human Verified Parse Result',
        attribute: 'humanVerifiedParseResult',
        value: params.get('humanVerifiedParseResult') ?? undefined,
        type: FilterType.SELECT,
        options: ['true', 'false'],
      },
    ]
  }, [params])

  if (isLoading || aiParseList === undefined) {
    return <div>Loading...</div>
  }

  const handleSort = (): void => {
    const currentSearchParams = new URLSearchParams(searchParams)
    const sortOrder = currentSearchParams.get('sortOrder') ?? undefined

    if (sortOrder === undefined) {
      currentSearchParams.set('sortOrder', 'Asc')
    } else if (sortOrder === 'Asc') {
      currentSearchParams.set('sortOrder', 'Desc')
    } else if (sortOrder === 'Desc') {
      currentSearchParams.set('sortOrder', 'Asc')
    }
    currentSearchParams.set('sortOrder', sortOrder === 'Asc' ? 'Desc' : 'Asc')
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  const aiParseHeaders = [
    { label: 'SID', attribute: 'sid', link: true },
    {
      label: 'CREATED',
      attribute: 'created',
      handleSort,
      sortDirection: (params.get('sortOrder') ?? undefined) as
        | 'Asc'
        | 'Desc'
        | undefined,
    },
    { label: 'LAST UPDATE', attribute: 'updated' },
    { label: 'PARSED DATE', attribute: 'parsedDate' },
    { label: 'REFERENCE SID', attribute: 'referenceSid', link: true },
    // TODO STATE is not available in the API yet
    // { label: 'STATE', attribute: 'state' },
    { label: 'VERIFIED', attribute: 'humanVerifiedParseResult' },
  ]

  const aiParseData = aiParseList?.aiParseList?.map((aiParse: AiParse) => ({
    sid: {
      value: aiParse.sid,
      handleClick: (event: KeyboardEvent) => {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/aiparses/${aiParse.sid}`, '_blank', 'noreferer')
        } else {
          navigate(`/aiparses/${aiParse.sid}`)
        }
      },
    },
    created: { value: aiParse.created },
    updated: { value: aiParse.updated },
    parsedDate: { value: aiParse.parsedDate },
    referenceSid: {
      value: aiParse.referenceSid,
      handleClick: (event: KeyboardEvent) => {
        const url = getSidPath(aiParse?.referenceSid)
        if (url === 'Unknown') return
        if (event.ctrlKey || event.metaKey) {
          window.open(url, '_blank', 'noreferer')
        } else {
          navigate(url)
        }
      },
    },
    // TODO STATE is not available in the API yet
    // state: { value: aiParse.state },
    humanVerifiedParseResult: {
      value:
        aiParse.humanVerifiedParseResult === null ? (
          ''
        ) : aiParse.humanVerifiedParseResult ? (
          <FontAwesomeIcon icon={light('check')} />
        ) : (
          <FontAwesomeIcon icon={light('xmark')} />
        ),
    },
  }))

  const handleNextPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseList?.page?.nextPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseList?.page?.nextPageToken)
  }

  const handlePrevPage = (): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('pageToken', aiParseList?.page?.previousPageToken)

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    setPageToken(aiParseList?.page?.previousPageToken)
  }

  const disablePrev =
    aiParseList?.page === undefined ||
    aiParseList?.page?.previousPageToken === null
  const disableNext =
    aiParseList?.page === undefined || aiParseList?.page?.nextPageToken === null

  const handleSearch = async (): Promise<void> => {
    const currentSearchParams = new URLSearchParams(searchParams)
    navigate(`${location.pathname}?${currentSearchParams.toString()}`, {
      replace: true,
    })
  }

  return (
    <>
      <div>
        <TopSection sm>
          <Headline className="text-offWhite-light leading-normal m-auto md:m-0">
            AI Parses
          </Headline>
        </TopSection>
      </div>
      <div>
        <TableFilters
          filters={tableFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
        />
        <div className="p-10">
          <Table data={aiParseData} headers={aiParseHeaders} />
        </div>
        <div className="mb-10">
          <Pagination
            disablePrev={disablePrev}
            disableNext={disableNext}
            goNextPage={handleNextPage}
            goPrevPage={handlePrevPage}
          />
        </div>
      </div>
    </>
  )
}

export default AiParseList
