import clsx from 'clsx'
import React, { type FC, type ReactNode } from 'react'
import { Link } from 'react-router-dom'

export interface NavItemProps {
  children: ReactNode
  url: string
  icon: ReactNode
  active?: boolean
  id?: string
}

export const NavItem: FC<NavItemProps> = ({
  icon,
  children,
  url,
  active = false,
  id = '',
}) => {
  return (
    <div className={`sm:rounded-xl xl:rounded-3xl ${active ? 'bg-white' : ''}`}>
      <Link
        to={url}
        className={clsx(
          'flex flex-col items-center xl:flex-row max-w-4 px-2 xl:pl-4 xl:pr-8 py-3 sm:py-2 font-medium'
        )}
      >
        <div className="mb-2 xl:mr-4 xl:mb-0">{icon}</div>
        {children}
      </Link>
    </div>
  )
}
