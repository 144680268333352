import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiOrganizationsSlice = createApi({
  reducerPath: 'organizationSettingsApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['Organizations'],
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: ({ pageToken }) => ({
        url: `/v1/Organizations`,
        params: {
          pageToken,
        },
      }),
      providesTags: ['Organizations'],
    }),
  }),
})

export const { useGetOrganizationsQuery } = apiOrganizationsSlice
