import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetAccessTokenMutation } from '../../services/auth/Auth'
import { useAppDispatch } from '../../store'
import {
  setAccessToken,
  setLoggedIn,
  setLoggedOut,
  setUserEmail,
  setUserSid,
} from '../../store/session'

interface AccessTokenResponse {
  accessToken: string
  email: string
  phoenixUserSid: string
}

export default function useAuthWithCookies(): void {
  const [getAccessToken] = useGetAccessTokenMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = (await getAccessToken({})) as {
          data: AccessTokenResponse
        }
        const { accessToken, email, phoenixUserSid } = data
        if (accessToken !== undefined) {
          dispatch(setLoggedIn())
          dispatch(setAccessToken(accessToken))
          dispatch(setUserEmail(email))
          dispatch(setUserSid(phoenixUserSid))
        }
      } catch (e: any) {
        console.log('Error on AuthCookie hook', e)
        dispatch(setLoggedOut())
        navigate('/login')
      }
    })()
  }, [])
}
