import { useFormik } from 'formik'
import React, {type FC, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {Button, Slideover, TextArea} from '../components'
import { slideOverSelector } from '../store/selectors'
import { closeSlideOver } from '../store/slideOvers'
import {useNavigate} from "react-router-dom";
import {useCreateAiConfigTestMutation} from "../services/aiconfigtest/AiConfigTestService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";

export const CreateAiConfigTest: FC = () => {
  const { name, data } = useSelector(slideOverSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [aiParseSids, setAiParseSids] = useState<string[]>(data?.AiParseSids === undefined ? [] : data.AiParseSids)
  const [warnAiParseSids, setWarnAiParseSids] = useState<boolean>(aiParseSids.length === 0)
  const [aiConfigSid, setAiConfigSid] = useState<string>(data?.AiConfigSid === undefined ? '' : data.AiConfigSid)
  const [warnAiConfigSid, setWarnAiConfigSid] = useState<boolean>(aiConfigSid.length === 0)
  const [createAiConfigTest] = useCreateAiConfigTestMutation()
  const formik = useFormik({
    initialValues: {
      aiConfigSid,
      aiParseSids
    },
    onSubmit: async () => {
      try {
        await createAiConfigTest({
          aiConfigSid,
          aiParseSids
        })
        dispatch(closeSlideOver())
        navigate(`/aiconfigtest`)
      } catch (error) {
        console.error(error)
      }
    },
  })

  const show = name === 'CreateAiConfigTest'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  const validateAiConfigSid = (inputSid:string | null): void => {
    if (inputSid === null || inputSid.length !== 30 || !/^(AC)[A-Za-z0-9]*$/.test(inputSid)) {
      setWarnAiConfigSid(true)
    } else {
      setWarnAiConfigSid(false);
      setAiConfigSid(inputSid);
    }
  }

  const buildAiParseSids = (inputSid:string | null): void => {
      if (inputSid === null || inputSid.length === 0) {
        setWarnAiParseSids(true)
      } else {
        const array = inputSid.match(/[^\r\n]+/g);
        let sidsOk = true;
        array?.forEach(value => {
          if (value.length !== 30 || !/^(AP)[A-Za-z0-9]*$/.test(value)) {
            sidsOk = false;
          }
        })
        if(sidsOk && array !== null) {
          setWarnAiParseSids(false);
          setAiParseSids(array);
        } else {
          setWarnAiParseSids(true);
        }
      }
  }

  return (
    <Slideover open={show} onClose={handleClose} title={`New AI Config Test`}>
      <div>
        <div>
          <div className="text-sm mb-4 block">
            <h3>AI Config Sid:</h3>
          </div>
          <div className="text-offBlack-dark text-sm block pb-5">
            <TextArea
                placeholder='$1'
                rows={1}
                defaultValue={aiConfigSid}
                onChange={validateAiConfigSid}
            ></TextArea>
            <div hidden={!warnAiConfigSid} className="pt-1">
              <span className="text-xs font-bold pt-1">An AI Config Sid is required</span>
            </div>
          </div>
        </div>
        <div>
          <div className="text-sm mb-4 block">
            <h3>AI Parse Sids:</h3>
          </div>
          <div className="text-offBlack-dark text-sm block">
            <TextArea
                rows={15}
                defaultValue={aiParseSids.join("\n")}
                onChange={buildAiParseSids}
            ></TextArea>
          </div>
          <div className="inline-flex">
            <div hidden={!warnAiParseSids} className="pr-1">
              <FontAwesomeIcon icon={light('warning')} color={'orange'} />
            </div>
            <span className="text-xs font-bold pt-1">Please, one AI Parse Sid per line</span>
          </div>
        </div>

        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={formik.isSubmitting} onClick={formik.handleSubmit} disabled={warnAiParseSids || warnAiConfigSid}>
            Create
          </Button>
        </div>
      </div>
    </Slideover>
  )
}
