import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from "../publicApiBaseQuery";

export const apiEventRelationSlice = createApi({
  reducerPath: 'eventRelationApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['eventRelations', 'eventRelationList'],
  endpoints: (builder) => ({
    getEventRelationList: builder.query({
      query: ({
        organizationSid,
        eventSid,
        eventType,
        relation,
        sortOrder,
        pageToken,
        fromWriter,
      }: {
        organizationSid?: string
        eventSid?: string
        eventType?: string
        relation?: string
        pageToken?: string
        sortOrder?: string
        fromWriter?: string
      }) => ({
        url: `/v1/EventRelations`,
        params: {
          organizationSid,
          eventSid,
          eventType,
          relation,
          sortOrder,
          pageToken,
          fromWriter,
        },
      }),
      providesTags: ['eventRelationList'],
    }),
    getEventRelations: builder.query({
      query: (sid: string) => `/v1/EventRelations/${sid}`,
      providesTags: ['eventRelations'],
    }),
    deleteEventRelation: builder.mutation({
      query: (sid: string) => ({
        url: `/v1/EventRelations/${sid}`,
        method: 'DELETE',
        responseHandler: async (response): Promise<{ status: number }> => {
          return { status: response.status }
        },
      }),
    }),
  }),
})

export const {
  useLazyGetEventRelationListQuery,
  useGetEventRelationsQuery,
  useDeleteEventRelationMutation,
} = apiEventRelationSlice
