export const ROOT = '/'
export const DASHBOARD = '/dashboard'
export const AI_PARSES = '/aiparses'
export const AI_PARSES_DETAILS = '/aiparses/:aiParseSid'
export const AI_PARSE_REVIEWS = '/aiparsereviews'
export const AI_PARSE_REVIEW_DETAILS = '/aiparsereviews/:aiParseReviewSid'
export const AI_PARSE_INPUTS = '/aiparseinputs'
export const AI_PARSE_INPUT_DETAILS = '/aiparseinputs/:aiParseInputSid'
export const AI_PROMPTS = '/aiprompt'
export const AI_PROMPTS_DETAILS = '/aiprompt/:aiPromptSid'
export const AI_CONFIG_TEST = '/aiconfigtest'
export const AI_CONFIG_TEST_DETAILS = '/aiconfigtest/:aiConfigTestSid'
export const AI_CONFIG = '/aiconfigs'
export const AI_CONFIG_DETAILS = '/aiconfigs/:aiConfigSid'
export const AI_CONFIG_ITEMS = '/aiconfigitems'
export const AI_CONFIG_ITEM_DETAILS = '/aiconfigitems/:aiConfigItemSid'
export const INBOUND_EMAILS = '/inboundemails'
export const INBOUND_EMAILS_DETAILS = '/inboundemails/:inboundEmailSid'
export const EVENT_RELATIONS = '/eventrelations'
export const EVENT_RELATIONS_DETAILS = '/eventrelations/:eventRelationSid'
export const ORGANIZATIONS = '/organizations'
export const LOGIN = '/login'
export const SIDS = '/sids'
export const SID_SEARCH = '/sids/:sid'
