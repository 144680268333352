export const downloadFile = (fileName: string, blob?: Blob, url?: string): void => {
  if (blob !== undefined && url !== undefined) {
    throw new Error('You can only pass in a blob or a url, not both')
  }
  let downloadUrl = ''
  if (blob !== undefined) {
    downloadUrl = URL.createObjectURL(blob)
  } else if (url !== undefined) {
    downloadUrl = url
  }
  const link = document.createElement('a')
  link.setAttribute('href', downloadUrl)
  link.setAttribute('download', fileName)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
