export const contentToJson = (json: string): any => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return JSON.parse('{"error":"invalid_json","content":"' + json + '"}')
  }
}

export const isValidJson = (json: string): boolean => {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}
