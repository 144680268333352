import { createSlice } from '@reduxjs/toolkit'

import { closeSlideOverAction, openSlideOverAction } from './actions/slideOvers'

export interface SlideOversState {
  name: string | null
  data?: any
  isFetching?: boolean
}

const initialState: SlideOversState = {
  name: null,
  data: null,
}

const slideOversSlice = createSlice({
  name: 'slideOvers',
  initialState,
  reducers: {
    openSlideOver: openSlideOverAction,
    closeSlideOver: closeSlideOverAction,
  },
})

export const { openSlideOver, closeSlideOver } = slideOversSlice.actions
export default slideOversSlice.reducer
