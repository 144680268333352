import { type ChangeEventHandler, type FC, useEffect, useState } from 'react'

interface TextAreaProps {
  defaultValue?: string
  onChange: (note: string) => void
  placeholder?: string
  rows?: number
  maxLength?: number
}

export const TextArea: FC<TextAreaProps> = ({
  placeholder,
  defaultValue = '',
  onChange,
  rows = 4,
  maxLength
}) => {
  const [text, setText] = useState(defaultValue)
  useEffect(() => {
    if (defaultValue !== text) {
      setText(defaultValue)
    }
  }, [defaultValue])

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e): void => {
    setText(e.target.value)
    onChange(e.target.value)
  }

  return (
    <textarea
      rows={rows}
      className="text-sm rounded-md border border-transparent transition shadow-md w-full resize-none"
      onChange={handleChange}
      placeholder={placeholder}
      value={text}
      maxLength={maxLength}
    ></textarea>
  )
}
